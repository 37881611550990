import React, { lazy, useState, useEffect } from "react";
import { Route, Routes, Navigate} from "react-router-dom";
import { routes } from "./helper";
import Advertising from "../pages/Advertising";
import Marketing from "../pages/Marketing";
import { useSelector } from 'react-redux';
const WelcomeScreen = lazy(() => import('../pages/WelcomeScreen'))
const Home = lazy(() => import('../pages/Home'))
const BrandDevelopment = lazy(() => import('../pages/BrandDevelopment'))
const Design = lazy(() => import('../pages/Design'))
const WebDesign = lazy(() => import('../pages/WebDesign'))
const AppDevelopment = lazy(() => import('../pages/AppDevelopment'))
const BrandDubai = lazy(() => import('../pages/BrandDubai'));
const BrandingStrategy = lazy(() => import('../pages/BrandingStrategy'));
const BrandMarketing=lazy(() => import('../pages/BrandingMarketing'));
const BrandMessaging =lazy(() => import('../pages/BrandingMessaging'));
const BrandIdentity =lazy(() => import('../pages/BrandingIdentity'));
const BrandPositioning =lazy(() => import('../pages/BrandingPositioning'));
const InboundMarketing =lazy(() => import('../pages/InboundMarketing'));
const ContentMarketing =lazy(() => import('../pages/ConentMarketing'));
const PerformanceMarketing =lazy(() => import('../pages/PerformanceMarketing'));
const CopyWritingMarketing =lazy(() => import('../pages/DigitalCopyWriting'));
const LogoDesign=lazy(() => import('../pages/LogoDesign'));
const UiUxDesign =lazy(() => import('../pages/UIUX'));
const VideoProduction =lazy(() => import('../pages/VideoProduction'));
const OohAdvertising =lazy(() => import('../pages/OhhAdvertising'));
const InfluencerMarketing =lazy(() => import('../pages/InfluncerMarketing'));
const PrintAdvertising =lazy(() => import('../pages/PrintingAdvertising'));
const PRMedia =lazy(() => import('../pages/PrintMedia'));
const MediaBuying =lazy(() => import('../pages/MediaBuying'));
const Digital = lazy(() => import('../pages/Digital'));
const DigitalMarketing = lazy(() => import('../pages/DigitalMarketing'))
const MarketingCollateral = lazy(() => import('../pages/MarketingCollateral'))
const CreativeWriting = lazy(() => import('../pages/CreativeWriting'))
const BackendDevelopment = lazy(() => import('../pages/BackendDevelopment'))
const SeoService = lazy(() => import('../pages/SeoService'))
const MotionGraphics = lazy(() => import('../pages/MotionGraphics'))
const SocialMediaMarketingServices = lazy(() => import('../pages/SocialMediaMarketingServices'))
const IllustrationDesign = lazy(() => import('../pages/IllustrationDesign'))
const AreaOfExpertise = lazy(() => import('../pages/AreasOfExpertise'))
const ContactUs = lazy(() => import('../pages/ContactUs'))
const AboutUs = lazy(() => import('../pages/AboutUs'))
const OurBlogs = lazy(() => import('../pages/OurBlogs'))
const Blog1 = lazy(() => import('../pages/Blog1'))

// const Blog2 = lazy(() => import('../pages/Blog2'))
// const Blog3 = lazy(() => import('../pages/Blog3'))
// const Blog4 = lazy(() => import('../pages/Blog4'))
const Portfolio = lazy(() => import('../pages/Portfolio'))

const ActOfKindness = lazy(() => import('../pages/ActOfKindness'))

const AbsoluteId = lazy(() => import('../pages/AbsoluteID'))

const ChildOfGreenEarth = lazy(() => import('../pages/ChildOfGreenEarth'))


const LawnCaseStudy = lazy(() => import('../pages/LawnCaseStudy'))

const BookSymphony = lazy(() => import('../pages/BookSymphony'))

const BookAConsultant = lazy(() => import('../pages/Consultant'))


const RevMe = lazy(() => import('../pages/Revme'))

const HairAtHome = lazy(() => import('../pages/HairAtHome'))
const MobleysGaming = lazy(() => import('../pages/MobleysGaming'))


const TheLifeUDeserve2Day = lazy(() => import('../pages/TheLifeUDeserve'))

 const BlogPage = lazy(() => import('../pages/Blog1/'))




const Router = () => {
    const region = useSelector((state) => state.region.region);
    return (
        <Routes>
            <Route path={routes.HOME_AE}element={<Home />}/>
            <Route path={routes.HOME_US}element={<Home />}/>

            
            <Route path={routes.BRAND_DEVELOPMENT_US}element={<BrandDevelopment />}/>
            {/* <Route path={region === 'ae' ? `/ae${routes.BRAND_DEVELOPMENT}` : `${routes.BRAND_DEVELOPMENT}`}element={<BrandDevelopment />}/> */}
            
            {/* <Route path={routes.DESIGN} element={<Design />} /> */}
            <Route path={routes.WEB_DESIGN_AE}element={<WebDesign />}/>
            <Route path={routes.WEB_DESIGN_US}element={<WebDesign />}/>

            
            
            <Route path={routes.APP_DEVELOPMENT_AE}element={<AppDevelopment />}/>
            <Route path={routes.APP_DEVELOPMENT_US}element={<AppDevelopment />}/>

           
            
            
             {/* Home Inner Pages urls */}
            <Route path={routes.BRAND_AE} element={<BrandDubai />} /> 
            <Route path={routes.BRAND_US} element={<BrandDubai />} /> 

            <Route path={routes.DIGITAL_AE} element={<Digital />} />
            <Route path={routes.DIGITAL_US} element={<Digital />} />     

            <Route path={routes.ADVERTISING_AE} element={<Advertising />} />
            <Route path={routes.ADVERTISING_US} element={<Advertising />} />

           
            <Route path={routes.MARKETIG_AE} element={<Marketing />} />
            <Route path={routes.MARKETIG_US} element={<Marketing />} />

            

            {/* Brand Strategy Inner Page Urls */}
            <Route path={routes.BRANDING_STRATEGY_AE} element={<BrandingStrategy />} />
            <Route path={routes.BRANDING_STRATEGY_US} element={<BrandingStrategy />} />
            
            <Route path={routes.BRANDING_MARKETING_AE} element={<BrandMarketing />} />
            <Route path={routes.BRANDING_MARKETING_US} element={<BrandMarketing />} />

            
            
            <Route path={routes.BRANDING_MESSAGING_AE} element={<BrandMessaging  />} />
            <Route path={routes.BRANDING_MESSAGING_US} element={<BrandMessaging  />} />

            <Route path={routes.BRANDING_IDENTITY_AE} element={<BrandIdentity  />} />
            <Route path={routes.BRANDING_IDENTITY_US} element={<BrandIdentity  />} />

            <Route path={routes.BRANDING_POSTIONING_AE} element={<BrandPositioning  />} />
            <Route path={routes.BRANDING_POSTIONING_US} element={<BrandPositioning  />} />

           
             {/* Marketing Inner Page */}
            <Route path={routes.INBOUND_MARKETING_AE} element={<InboundMarketing  />} />
            <Route path={routes.INBOUND_MARKETING_US} element={<InboundMarketing  />} />


            
            <Route path={routes.CONTENT_MARKETING_AE} element={<ContentMarketing  />} /> 
            <Route path={routes.CONTENT_MARKETING_US} element={<ContentMarketing  />} /> 


            <Route path={routes.PERFORMANCE_MARKETING_AE} element={<PerformanceMarketing  />} />
            <Route path={routes.PERFORMANCE_MARKETING_US} element={<PerformanceMarketing  />} />

            
            <Route path={routes.COPY_WRITING_MARKETING_AE} element={<CopyWritingMarketing  />} />
            <Route path={routes.COPY_WRITING_MARKETING_US} element={<CopyWritingMarketing  />} />

            
            <Route path={routes.DIGITAL_MARKETING_AE} element={<DigitalMarketing  />} />
            <Route path={routes.DIGITAL_MARKETING_US} element={<DigitalMarketing  />} />


            {/* Digital Innner PAge Urls */}
            <Route path={routes.UI_UX_DESIGN_AE} element={<UiUxDesign  />} />
            <Route path={routes.UI_UX_DESIGN_US} element={<UiUxDesign  />} />

            
            <Route path={routes.VIDEO_PRODUCTION_AE} element={<VideoProduction  />} />
            <Route path={routes.VIDEO_PRODUCTION_US} element={<VideoProduction  />} />

            <Route path={routes.APP_DEVELOPMENT_AE} element={<AppDevelopment  />} />
            <Route path={routes.APP_DEVELOPMENT_US} element={<AppDevelopment  />} />

            <Route path={routes.WEB_DESIGN_AE} element={<WebDesign  />} /> 
            <Route path={routes.WEB_DESIGN_US} element={<WebDesign  />} /> 

            <Route path={routes.ILLUSTRATION_DESIGN_AE} element={<IllustrationDesign  />} /> 
            <Route path={routes.ILLUSTRATION_DESIGN_US} element={<IllustrationDesign  />} /> 

          
            {/* Advertising Inner Page Urls */}
            <Route path={routes.BRANDING_OOH_ADVERTISING_AE} element={<OohAdvertising  />} />
            <Route path={routes.BRANDING_OOH_ADVERTISING_US} element={<OohAdvertising  />} />
            
            <Route path={routes.INFLUENCER_MARKETING_AE} element={<InfluencerMarketing  />} /> 
            <Route path={routes.INFLUENCER_MARKETING_US} element={<InfluencerMarketing  />} /> 

            
            <Route path={routes.PRINT_ADVERTISING_AE} element={<PrintAdvertising  />} /> 
            <Route path={routes.PRINT_ADVERTISING_US} element={<PrintAdvertising  />} /> 

            <Route path={routes.PR_MEDIA_AE} element={<PRMedia  />} />
            <Route path={routes.PR_MEDIA_US} element={<PRMedia  />} />

            <Route path={routes.MEDIA_BUYING_AE} element={<MediaBuying />} />
            <Route path={routes.MEDIA_BUYING_US} element={<MediaBuying />} />

           
            

            {/* <Route path={routes.CREATIVE_LOGO_DESIGNS}element={<CreativeLogoDesigns />}/> */}
            <Route path={routes.MARKETING_COLLATERAL_AE}element={<MarketingCollateral />}/>
            <Route path={routes.MARKETING_COLLATERAL_US}element={<MarketingCollateral />}/>

            
            <Route path={routes.CREATIVE_WRITING_AE}element={<CreativeWriting />}/>
            <Route path={routes.CREATIVE_WRITING_US}element={<CreativeWriting />}/>

            
            <Route path={routes.BACKEND_DEVELOPMENT_SERVICES_AE}element={<BackendDevelopment />}/>
            <Route path={routes.BACKEND_DEVELOPMENT_SERVICES_US}element={<BackendDevelopment />}/>

            
            <Route path={routes.SEO_SERVICES_AE}element={<SeoService />}/>
            <Route path={routes.SEO_SERVICES_US}element={<SeoService />}/>

            

            <Route path={routes.MOTION_GRAPHICS_AE}element={<MotionGraphics />}/>
            <Route path={routes.MOTION_GRAPHICS_US}element={<MotionGraphics />}/>

            
            <Route path={routes.SOCIAL_MEDIA_MARKETING_SERVICES_AE}element={<SocialMediaMarketingServices />}/>
            <Route path={routes.SOCIAL_MEDIA_MARKETING_SERVICES_US}element={<SocialMediaMarketingServices />}/>

            <Route path={routes.ILLUSTRATION_DESIGN_AE}element={<IllustrationDesign />}/>
            <Route path={routes.ILLUSTRATION_DESIGN_US}element={<IllustrationDesign />}/>

            <Route path={routes.LOGO_DESIGN_AE}element={<LogoDesign />}/>
            <Route path={routes.CREATIVE_LOGO_DESIGNS_US}element={<LogoDesign />}/>

            
            <Route path={routes.AREA_OF_EXPERTISE_AE}element={<AreaOfExpertise />}/>
            <Route path={routes.AREA_OF_EXPERTISE_US}element={<AreaOfExpertise />}/>

             
            <Route path={routes.CONTACT_US}element={<ContactUs />}/>
            <Route path={routes.CONTACT_US_AE}element={<ContactUs />}/>

            <Route path={routes.ABOUT_US}element={<AboutUs />}/>
            <Route path={routes.ABOUT_US_AE}element={<AboutUs />}/>

            <Route path={routes.OUR_BLOGS_US}element={<OurBlogs />}/>
            <Route path={routes.OUR_BLOGS_AE}element={<OurBlogs />}/>

            <Route path={routes.BLOG_PAGE} element={<Blog1 />} />

            {/*<Route path={routes.BLOG2} element={<Blog2 />} />
            <Route path={routes.BLOG3} element={<Blog3 />} />
            <Route path={routes.BLOG4} element={<Blog4 />} /> */}
             <Route path={routes.PORTFOLIO_AE}element={<Portfolio />}/>
             <Route path={routes.PORTFOLIO_US}element={<Portfolio />}/>

           
            {/* <Route path={routes.LOGO_DESIGN} element={<LogoDesign />} /> */}
            <Route path={routes.ACT_OF_KINDNESS_AE} element={<ActOfKindness />} />
            <Route path={routes.ABSOLUTE_ID_AE} element={<AbsoluteId />} />
            <Route path={routes.CHILD_OF_GREEN_EARTH_AE} element={<ChildOfGreenEarth />} />
            <Route path={routes.LAWN_CASE_STUDY_AE} element={<LawnCaseStudy />} />
            <Route path={routes.BOOK_SYMPHONY_AE} element={<BookSymphony />} />
            <Route path={routes.BOOK_A_CONSULTANT_AE} element={<BookAConsultant />} />
            <Route path={routes.REV_ME_AE} element={<RevMe />} />  
            <Route path={routes.HAIR_AT_HOME_AE} element={<HairAtHome />} />
            <Route path={routes.MOBLEYS_GAMING_AE} element={<MobleysGaming />} />
            <Route path={routes.THE_LIFE_U_DESERVE_DAY_AE} element={<TheLifeUDeserve2Day />} />

            <Route path={routes.ACT_OF_KINDNESS_US} element={<ActOfKindness />} />
            <Route path={routes.ABSOLUTE_ID_US} element={<AbsoluteId />} />
            <Route path={routes.CHILD_OF_GREEN_EARTH_US} element={<ChildOfGreenEarth />} />
            <Route path={routes.LAWN_CASE_STUDY_US} element={<LawnCaseStudy />} />
            <Route path={routes.BOOK_SYMPHONY_US} element={<BookSymphony />} />
            <Route path={routes.BOOK_A_CONSULTANT_US} element={<BookAConsultant />} />
            <Route path={routes.REV_ME_US} element={<RevMe />} />  
            <Route path={routes.HAIR_AT_HOME_US} element={<HairAtHome />} />
            <Route path={routes.MOBLEYS_GAMING_US} element={<MobleysGaming />} />
            <Route path={routes.THE_LIFE_U_DESERVE_DAY_US} element={<TheLifeUDeserve2Day />} />
            

            <Route path={routes.BLOG_PAGE_AE} element={<BlogPage />} />
            <Route path={routes.BLOG_PAGE_US} element={<BlogPage />} />

            {/* <Route path={routes.BLOG_PAGE} element={<BlogPage />} /> */}
        </Routes>
    )
}

export default Router;
