import React, { useState, memo } from 'react';
import Heading from '../../elements/Heading';
import Arrow from '../../../assets/images/Arrow.webp'
import ArrowLeft from '../../../assets/images/Arrow-left.webp'
import { useNavigate } from "react-router-dom";
import "./slider.css"
import Slider from "react-slick";


const CarouselModule = memo((props) => {

  const NextArrow = ({ onClick }) => {
    return (
      <div className="arrow next arrowForward" onClick={onClick}>
        <img src={Arrow} alt="arrow-here" loading='lazy' width="100%" height="100%" />
      </div>
    );
  };

  const PrevArrow = ({ onClick }) => {
    return (
      <div className="arrow prev" onClick={onClick}>
        <img src={ArrowLeft} alt="arrow-here" loading='lazy'/>
      </div>
    );
  };

  const [imageIndex, setImageIndex] = useState(0);

  const settings = {
    // autoplay:true,
    easing:'linear',
    
    // autoplaySpeed:3000,
    infinite: true,
    lazyLoad: true,
    dots:false,
    speed: 1000,
    responsive: [{
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }],
    slidesToShow: 3,
    centerMode: true,
    centerPadding: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (current, next) => setImageIndex(next),
  };
  const { cards } = props;
  const navigate = useNavigate()


  return (

    <div className='pb-5 pt-3' style={{ position: 'relative' }}>
      <Slider {...settings} className='pb-3'>
        {
          cards.map((item, idx) => {
            return (
              <div className={idx === imageIndex ? "slide activeSlide carousal-image" : "slide carousal-image"}>
                <img className='w-100 h-100' src={item.image} loading='lazy' alt='ideas_img' />
                <div className='child-carousel text-center text-white'>
                  <Heading bold size="xxs" fontFamily={"bold-font"} fontSize={"25px"} heading={item.heading} />
                  <button onClick={() => navigate(item.link)} className='p-1 px-3 carousal-button' >View Case Study</button>
                </div>
              </div>
            )
          })
        }
      </Slider>
    </div>
  );
})

export default CarouselModule;