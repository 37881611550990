import { Country } from "../content/Country";

export const getRegion = ()=>{
      const resolvedTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const tzArr = resolvedTimeZone.split("/");
      const city = tzArr[tzArr.length - 1];
      const country = Country[city];
      console.log(country,"My Country ")
      return mapCountryToContinent(country)
}

const mapCountryToContinent = (countryCode) => {
    // Define your countryToContinent mapping here
    const countryToContinent = {
      // North America
      "United States": "North America",
      "Canada": "North America",
      "Mexico": "North America",
      "Belize": "North America",
      "Guatemala": "North America",
      "El Salvador": "North America",
      "Honduras": "North America",
      "Costa Rica": "North America",
      "Nicaragua": "North America",
      "Panama": "North America",
      "Bahamas": "North America",
      "Cuba": "North America",
      "Jamaica": "North America",
      "Haiti": "North America",
      "Dominican Republic": "North America",
      "Puerto Rico": "North America",
      "Dominica": "North America",
      "Saint Kitts and Nevis": "North America",
      "Antigua and Barbuda": "North America",
      "Saint Vincent and the Grenadines": "North America",
      "Saint Lucia": "North America",
      "Barbados": "North America",
      "Grenada": "North America",
      "Trinidad and Tobago": "North America",
      // Add more countries and continents as needed
    };  
    return countryToContinent[countryCode] || "ae";
  };