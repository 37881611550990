import "./index.css";
import React, { memo, useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Swal from "sweetalert2";
import { colors } from "../../../utils/colors";
import Heading from "../../elements/Heading";
import Button from "../../elements/Button";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useSelector } from 'react-redux';
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import TextField from '@mui/material/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { FormControl, InputLabel, Select, OutlinedInput, } from '@material-ui/core';
import { Autocomplete, } from '@mui/material';
const useStyles = makeStyles((theme) => ({
  customTextField: {
    '& label': {
      color: 'white', // Change the label color when not focused
    },
    '& label.Mui-focused': {
      color: 'white',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'white', // Default border color
      },
      '&:hover fieldset': {
        borderColor: 'white', // Border color on hover
      },
      '&.Mui-focused fieldset': {
        borderColor: 'white',
      },
      '& input': {
        color: 'white', // Change text color to white
      },
      '& .MuiSelect-root': {
        '& .custom-select-menu-item.Mui-selected': {
          Color: 'white', // Remove background color for the selected item
        },
      },
      '& input:-webkit-input-placeholder': {
        color: 'white',
      },
      '& input::-moz-placeholder': {
        color: 'white',
      },
      '& input:-ms-input-placeholder': {
        color: 'white',
      },
      "& .MuiSelect-icon": {
        color: "white", // Change the select icon color
      },
      "& .MuiSelect-select.MuiSelect-select": {
        color: "white", // Change the selected text color
      },
      "& .MuiSelect-root.MuiSelect-root": {
        color: "white", // Change the text color
      },
      "& .MuiSelect-icon": {
        color: "white", // Change the select icon color
      },
      "& .MuiSelect-select.MuiSelect-select": {
        color: "white !important", // Change the selected text color to white
      },
      "& .MuiSelect-root.MuiSelect-root": {
        color: "white !important", // Change the text color to white
      },
      "& .MuiAutocomplete-option.Mui-selected": {
        "&.MuiAutocomplete-option .MuiTypography-root": {
          color: "white !important", // Change the text color of selected option
        },

        '& .MuiAutocomplete-option.Mui-selected': {
          '& .MuiTypography-root': {
            color: 'white !important',
          },
        },
        // '& .MuiChip-label .MuiChip-labelMedium .css-6od3lo-MuiChip-label':{
        //   color: 'white !important'
        // }
      },
    }
  },

}));
const FooterCaseStudy = memo((props) => {
  const region = useSelector((state) => state.region.region);
  // useEffect(() => {
  //     window.scrollTo(0, 0);
  // }, [])
  const {
    title,
    subtitle,
    button,
    simple,
    textColor,
    id
  } = props;


  // const [formState, setFormState] = useState({
  //     your_name: "",
  //     your_email: "",
  //     your_phone: "",
  //     your_services:[""]
  // });
  // const handleInput = (event) => {
  //     const name = event.target.name;
  //     const value = event.target.value;


  //     setFormState((prev) => {
  //         return { ...prev, [name]: value }
  //     });
  // };
  const [your_name, setFullName] = useState("");
  const [your_email, setEmail] = useState("");
  const [your_phone, setPhoneno] = useState("");
  const [your_services, setServices] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [emailError, setEmailError] = useState('');
  const Servives = [
    { value: '', label: 'Choose a Services' },
    { value: 'Brand And Strategy', label: 'Brand And Strategy' },
    { value: 'Marketing', label: 'Marketing' },
    { value: 'Digital', label: 'Digital' },
    { value: 'Advertising', label: 'Advertising' },
  ];

  const handlePhoneNumberChange = (value) => {
    setPhoneno(value);
  };
  const handleChange = (event) => {
    const { value } = event.target;
    setServices(value)
  };

  // ---------------------
  const validateEmail = () => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    const isValid = emailRegex.test(your_email);
    
    setIsValidEmail(isValid);

    if (!isValid) {
      setEmailError('Invalid email address');
    } else {
      setEmailError('');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(your_name,your_email,your_phone,your_services,"Form Data Home ")
   
    if (!isValidEmail) {
      // Email is invalid
      Swal.fire("Invalid Email", "Please provide a valid email address.", "error");
    } else if (
      your_name.length > 0 &&
      your_email.length > 0 &&
      your_phone.length > 0 &&
      your_services.length > 0
    ) {
      // All fields are filled out and the email is valid, so you can proceed with submission
      let formData = new FormData();
      formData.append('your_name', your_name);
      formData.append('your_email', your_email);
      formData.append('your_phone', your_phone);
      formData.append('your_services', your_services);
  
      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }
      try {
        await axios.post(
          "https://blog.icreativestudio.com/wp-json/contact-form-7/v1/contact-forms/21928/feedback",
          formData,
          config
        ).then((response) => {
          setFullName("");
          setEmail("");
          setPhoneno("");
          setServices("");
          Swal.fire(
            "Successfully sent",
            "That thing is still around?",
            "success"
          );
        });
      } catch (error) {
        Swal.fire(
          "Error",
          error.message,
          "error"
        );
      }
    } else {
      Swal.fire("Incomplete Information", "Please fill all fields.", "error");
    }
    
    try {
      
    } catch (error) {
      
    }

    

  };
  
  // ---------------------
  const classes = useStyles();
  return (
    <Container fluid className="contact-padding py-lg-5 py-md-5 py-5 " style={{ backgroundColor: colors.black, maxHeigh: '100vh' }} id={id}>

      <Row className="pt-5 py-lg-5 pt-md-0 py-5 m-0">
        <Col md={6} xs={12} className="d-flex flex-column justify-content-center px-0 px-lg-5 px-md-5">
          <div className="px-4 px-lg-4 pt-4 pt-lg-0 pt-md-0 pb-3 pb-lg-0 pb-md-0">
            <Heading
              className=""
              size="lg"
              fontFamily={"bold-font"}
              heading={title}
              color={colors.white}
            />
          </div>
        </Col>
        <Col md={6} >
          <Row>
            <Col>
              <Row>
                <Col lg={6} md={6} className="w-100 ">
                  <Row className="px-lg-5 px-3 py-md-0 py-lg-3 py-2">
                    <Col lg={12} md={12} xs={12}>
                      <Heading size="xxs" fontSize="20px" heading={subtitle} color={textColor} />
                    </Col>
                    <Col lg={6} md={6} xs={12} className="px-2 px-lg-2 py-3">
                      {/* <label className="w-100">
                                                <input
                                                    type="text"
                                                    className="input-field2 form-control py-0"
                                                    value={your_name}
                                                    required="true"
                                                    onChange={(e) => setFullName(e.target.value)}
                                                    id="your_name"
                                                    name="your_name"
                                                    placeholder="Full Name*"
                                                />
                                            </label> */}
                      <TextField id="outlined-basic" variant="outlined"
                        label="Full Name"
                        onChange={(e) => setFullName(e.target.value)}
                        value={your_name}
                        type="text"
                        className={classes.customTextField}
                        fullWidth
                        autoComplete="off"
                      />
                    </Col>
                    <Col lg={6} md={6} xs={12} className="px-2 px-lg-3 py-3">
                      <PhoneInput
                        country={region == "ae" ? 'ae' : "us"}
                        value={your_phone}
                        onChange={handlePhoneNumberChange}
                        inputStyle={{
                          color: "white",
                        }}
                        containerStyle={{ borderColor: 'white !important',
                        border: "1px solid white",
                         borderRadius:"6px"
                      }}

                      />


                      {/* 
                                              <TextField
                                      id="outlined-basic"
                                    label="Phone Number"
                                   type="number"
                                onChange={handlePhoneNumberChange}
                              className={classes.customTextField}
                            fullWidth
                           variant="outlined"
            /> */}


                    </Col>

                  </Row>

                </Col>
                <Col lg={6} md={6} className="w-100 pt-lg-3">
                  <Row className="px-lg-5 px-3">
                    <Col lg={6} md={6} xs={12} className="px-2 px-lg-2 py-lg-3 py-3">
                      {/* <label className="w-100">

                                                <input
                                                    type="email"
                                                    className="input-field2 form-control py-0"
                                                    value={your_email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    id="your_email"
                                                    name="your_email"
                                                    placeholder="Email*"
                                                    required="true"
                                                />
                                            </label> */}


                      <TextField id="outlined-basic" variant="outlined"
                        label="Email"
                        type="email"
                        onChange={(e) => setEmail(e.target.value)}
                        value={your_email}
                        className={classes.customTextField}
                        onBlur={validateEmail}
                        error={!isValidEmail}
                         helperText={emailError}
                        fullWidth
                        autoComplete="off"
                      />
                    </Col>
                    {/* <Col lg={6} md={6} xs={12} className="px-2 px-lg-2  py-3">
                                            <label className="w-100">
                                                <input
                                                    type="text"
                                                    className="input-field2 form-control py-0"
                                                    value={your_services}
                                                    onChange={(e) => setServices(e.target.value)}
                                                    id="your_services"
                                                    name="your_services"
                                                    placeholder="Services*"
                                                />
                                            </label>
                                        </Col> */}

                    <Col lg={6} md={6} xs={12} className="px-2 px-lg-3  py-3  ">

                      {/* <label className="w-100">
        <select
            className="input-field2 form-control py-0"
            value={your_services}
            onChange={(e) => setServices(e.target.value)}
            id="your_services"
            name="your_services"
        >
            <option value="" style={{color:"black"}}>Select a Service*</option>
            <option value="service1"style={{color:"black"}}>Brand And Strategy</option>
            <option value="service2"style={{color:"black"}}>Marketing</option>
            <option value="service3"style={{color:"black"}}>Digital</option>
            <option value="service3"style={{color:"black"}}>Advertising</option>
        </select>
    </label> */}
                      {/* <Autocomplete
              multiple
              id="custom-multiple-select"
              options={options}

              value={selectedOptions}
              className={classes.customTextField}
              onChange={(event, newValue) => {
                setSelectedOptions(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  type="text"
                  value={selectedOptions}
                  variant="outlined"
                  label="Select  Services"
                  className={classes.customTextField}
                  style={{
                    color: 'white', // Set the text color to white
                  }}
                />

              )}
            /> */}

                      <TextField
                        id="outlined-select-services"
                        variant="outlined"
                        select
                        className={classes.customTextField}
                        label="Select a Services"
                        fullWidth
                        value={your_services}
                        onChange={handleChange}
                        autoComplete="off"
                      >
                        {Servives.map((option) => (
                          <MenuItem key={option.value} value={option.value}

                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Col>

                    <Col className="pt-3" >
                      <Button
                        onClick={handleSubmit}
                        title={button.title}
                        backgroundColor={button.backgroundColor}
                        textColor={button.textColor}
                        borderColor={simple ? button.borderColor : "gradient"}
                        gradientHover={button.gradientHover && true}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>

    </Container>
  );
});

export default FooterCaseStudy;

