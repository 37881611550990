import React, { memo, Suspense, useState, useEffect } from "react";
import { colors } from "../../utils/colors";
import { content } from "../../content"
import { useSelector } from 'react-redux';
import SimilarBlogs from "../../components/modules/SimilarBlog";
import ICS from "../../assets/images/ICS.webp"
import Slide1 from "../../assets/images/join-Aok-slide.webp";
import Slide2 from "../../assets/images/Revme-slides.webp";
import Slide3 from "../../assets/images/Absolute-ID-slide.webp";
import Slide4 from "../../assets/images/children-of-green-earth-slide.webp";
import Slide5 from "../../assets/images/book-symphony-slide.webp";
import Slide6 from "../../assets/images/hair-at-home-slide.webp";
import Slide7 from "../../assets/images/mobiley-game-slide.webp";
import Slide8 from "../../assets/images/desire-2day-slide.webp";
import Slide9 from "../../assets/images/LGP-Lawn-care-slide.webp";
import BrandHeader from "../../components/modules/BrandHeader";
import Header from "../../components/modules/Header";
import InfoCard from "../../components/modules/InfoCard";
import DrawerNav from "../../components/modules/DrawerNav";
import FAQs from "../../components/modules/FAQs";
import CarousalComponent from "../../components/modules/CarousalContent";
import ServicesOffer from "../../components/modules/ServicesOffer";
import Helmet from "react-helmet"
import BrandingConsultance from "../../components/modules/BrandingConultancy";
import CaseStudyFooter from "../../components/modules/CaseStudyFooter";
import { GET_ALL_BLOGS } from "../../services/queries"
import { useLazyQuery } from "@apollo/client"
import BrandingFooter from "../../components/modules/BrandingFooter";
import Creativity from "../../components/modules/Creativity";
import HomeHeader from "../../components/modules/HomeHeader";
const Advertising = memo(() => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  const [isOpen, setIsOpen] = useState(false);
  const region = useSelector((state) => state.region.region);
  const [blogs, setBlogs] = React.useState([]);
  const [endCursor, setEndCursor] = React.useState(null);
  const [hasNextPage, setHasNextPage] = React.useState(false);
  const [posts, { data: users, loading }] = useLazyQuery(GET_ALL_BLOGS(endCursor))
  const { WeDoAdvertising,
    WeDoAdvertising1, WeDoAdvertising2, WeDoAdvertising3, WeDoAdvertising4,
    WeDoAdvertisingContent, ScheduleAnECoffee,
    NavigatingSuccess, NavigatingSuccessContent,
    NavigatingSuccessContentUs, WeDoAdvertisingUs,
    OurAdvertisingUs, OurAdvertisingUsContent,
    PotentialAdvertising, PotentialAdvertisingContent, TrustedAdAgency,
    OurAdvertisingServices, OurAdvertisingServicesContent,
    OurAdvertisingServices1, AdvertisingServicesContent1,
    OurAdvertisingServices2, AdvertisingServicesContent2,
    OurAdvertisingServices3, AdvertisingServicesContent3,
    OurAdvertisingServices4, AdvertisingServicesContent4,
    OurAdvertisingServices5, AdvertisingServicesContent5,
    ProjectsThatWeConquer, ProjectsThatWeConquerContent,
    NavigatingSuccessUsHeading3,
    NavigatingSuccessUsHeading2,
    NavigatingSuccessUsHeading1,
    IgniteYourBrand, PerspectiveOnSuccess, PerspectiveOnSuccessContent,
    IlluminatingCreativity, IlluminatingCreativityContent,
    SettingDataDrivenTrends, SettingDataDrivenTrendsContent,
    ElevateGrowth, ElevateGrowthContent,
    ProjectsThatWeConquerContentUs, PotentialAdvertisingContentUs,
    GoTrustedAdAgency, IgniteYourBrandJourney,
    PerspectiveSuccessUs, PerspectiveSuccessUsContent,
    SettingTrendsUs, SettingTrendsUsContent,
    IlluminatingCreativityUs, IlluminatingCreativityUsContent,
    ElevateBusinessGrowthUs, ElevateBusinessGrowthUsContent,
    ViewPortfolio,
    NavigatingSuccessHeading2,
    NavigatingSuccessHeading3,
    NavigatingSuccessHeading4,
    AdvertisingQuestion1, AdvertisingQuestion2,
    AdvertisingQuestion3, AdvertisingQuestion4,
    AdvertisingQuestion5, AdvertisingQuestion6,
    AdvertisingQuestion7, AdvertisingQuestion8,
    AdvertisingAnswer1, AdvertisingAnswer2,
    AdvertisingAnswer3, AdvertisingAnswer4,
    AdvertisingAnswer5, AdvertisingAnswer6,
    AdvertisingAnswer7, AdvertisingAnswer8,
    AdvertisingUsQuestion1, AdvertisingUsQuestion2,
    AdvertisingUsQuestion3, AdvertisingUsQuestion4,
    AdvertisingUsQuestion5, AdvertisingUsQuestion6,
    AdvertisingUsQuestion7, AdvertisingUsQuestion8,
    AdvertisingUsAnswer1, AdvertisingUsAnswer2,
    AdvertisingUsAnswer3, AdvertisingUsAnswer4,
    AdvertisingUsAnswer5, AdvertisingUsAnswer6,
    AdvertisingUsAnswer7, AdvertisingUsAnswer8,
    BarndingService1, BarndingService2, BarndingService3, BarndingService4,
    BarndingService5, RequestAQuote, Email, FAQ, ViewMore, InSightBlogs,
    WeOnlySpeak, Liketotalk, SendAMessage, Blogs, Portfolio, WhoWeAre, Experties, ContactUs,
    PhoneNumber, EmailValue, Address, AddressAe, Newsletter, NewsletterContent,
    CopyRight, FollowUs,
    PhoneNumberAE, PhoneNumberUS
  } = content;
  useEffect(() => {

    getBlogs()

  }, [hasNextPage])

  const getBlogs = () => {
    posts().then((res) => {
      let blog = [...blogs]
      res.data.posts.edges.map((item, index) => {
        blog.push({
          image: item.node.featuredImage.node.sourceUrl,
          title: `Blog - ${formatDate(item.node.date)}`,
          subtitle: item.node.title,
          link: item.node.uri,
          id: item.node.id,
          key: index
        })
      })

      setEndCursor(res.data.posts.pageInfo.endCursor)
      setBlogs(blog)
      setHasNextPage(false)

    }).catch((err) => {



    })
  }

  function formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('/');
  }
  return (
    <>
      {region == "ae" ? <Helmet>
        <script type="application/ld+json" key="item-jsonld1" >
          {
            ` {
              "@context": "https://schema.org/", 
              "@type": "BreadcrumbList", 
              "itemListElement": [{
                "@type": "ListItem", 
                "position": 1, 
                "name": "iCreativeStudio",
                "item": "https://icreativestudio.com/ae-en"  
              },{
                "@type": "ListItem", 
                "position": 2, 
                "name": "Advertising Agency in Dubai | iCreativeStudio",
                "item": "https://icreativestudio.com/ae-en/advertising-agency/"  
              }]
            }
            
`
          }
        </script>

        <script type="application/ld+json" key="item-jsonld2" >
          {
            `{
              "@context": "https://schema.org",
              "@type": "ProfessionalService",
              "name": "Advertising Agency in Dubai | iCreativeStudio",
              "url": "https://icreativestudio.com/ae-en/advertising-agency/",
            "description": "iCreativeStudio is a leading Advertising Agency in Dubai, working with different brands across the Mena, providing OHH, Print, and PR services.",
              "brand": {
                "@type": "Brand",
                "name": "iCreativeStudio"
              }
    }

            `
          }
        </script>
        <script type="application/ld+json" key="item-jsonld3" >
          {
            `{
              "@context": "https://schema.org",
              "@type": "FAQPage",
              "mainEntity": [{
                "@type": "Question",
                "name": "What services does your advertising agency offer?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "We offer a comprehensive range of advertising services, including but not limited to branding, digital marketing, social media management, content creation, media buying, and campaign strategy development."
                }
              },{
                "@type": "Question",
                "name": "Do you work with businesses of all sizes?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Yes, we work with businesses of all sizes, from startups and small businesses to larger enterprises. Our services are tailored to meet each client's unique needs and goals."
                }
              },{
                "@type": "Question",
                "name": "What makes your agency different from others?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Our advertising agency stands out due to our creative approach, data-driven strategies, and commitment to delivering results. We combine artistic vision with analytical insights to create effective advertising campaigns."
                }
              },{
                "@type": "Question",
                "name": "Can you handle both online and offline advertising?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Absolutely. Our agency has expertise in both online and offline advertising channels. This includes digital platforms, social media, print media, television, radio, and more."
                }
              },{
                "@type": "Question",
                "name": "How long does it take to see results from an advertising campaign?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "The timeline for seeing results can vary based on factors such as the campaign's goals, the chosen channels, and the industry. We strive to provide a realistic timeframe during the strategy planning phase."
                }
              },{
                "@type": "Question",
                "name": "What is the cost of your advertising services?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "The cost of our services depends on the scope of the project, the services required, and the complexity of the campaign. We offer customized solutions to fit different budgets and will provide a detailed proposal after understanding your needs."
                }
              },{
                "@type": "Question",
                "name": "Can you help with ongoing campaign management and optimization?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Yes, we offer ongoing campaign management and optimization services to ensure that your advertising efforts continue to perform at their best and adapt to changing market dynamics."
                }
              },{
                "@type": "Question",
                "name": "How can I get started with your agency?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Getting started is easy. Simply reach out to us through our contact page, and we'll schedule a consultation to discuss your needs and goals. From there, we'll work together to create a tailored advertising strategy."
                }
              }]
            }
            `
          }
        </script>
        <title>Advertising Agency in Dubai | iCreativeStudio</title>
        <meta name="robots" content="follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large" />
        <link rel="canonical" href="https://icreativestudio.com/ae-en/advertising-agency" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta name="title" property="og:title" content="Advertising Agency in Dubai | iCreativeStudio" />
        <meta name="description" property="og:description" content="iCreativeStudio is a leading Advertising Agency in Dubai, working with different brands across the Mena, providing OHH, Print, and PR services." />
        <meta
          name="keywords"
          property="og:keyword"
          content="Advertising Agency in Dubai"
        />
        <meta property="og:url" content="https://icreativesol.com/" />
        <meta property="og:site_name" content="iCreativeStudio" />
        <meta property="og:updated_time" content="2022-07-06T15:47:17+05:00" />
        <meta property="og:image" content="https://icreativestudio.com/static/media/ICS.d03a1cee52137330fc6a7900214145f4.svg" />
        <meta property="og:image:secure_url" content="https://icreativestudio.com/static/media/ICS.d03a1cee52137330fc6a7900214145f4.svg" />
        <meta property="og:image:width" content="512" />
        <meta property="og:image:height" content="512" />
        <meta property="og:image:alt" content="iCreativeStudio" />
        <meta property="og:image:type" content="image/png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Advertising Agency in Dubai | iCreativeStudio" />
        <meta name="twitter:description" content="iCreativeStudio is a leading Advertising Agency in Dubai, working with different brands across the Mena, providing OHH, Print, and PR services." />
        <meta name="twitter:image" content="https://icreativestudio.com/static/media/ICS.d03a1cee52137330fc6a7900214145f4.svg" />
        <meta name="twitter:label1" content="Written by" />
        <meta name="twitter:data1" content="admin" />
        <meta name="twitter:label2" content="Time to read" />
        <meta name="twitter:data2" content="4 minutes" />
      </Helmet> :
        <Helmet>
          <script type="application/ld+json" key="item-jsonld1" >
            {
              ` 
        {
          "@context": "https://schema.org",
          "@type": "ProfessionalService",
          "name": "Digital Advertising Agency US | iCreativeStudio",
          "url": "https://icreativestudio.com/us-en/advertising-agency",
        "description": "Our advertising agency US illuminates your brand's potential, infusing every campaign with an artistic flair that commands attention.",
          "brand": {
            "@type": "Brand",
            "name": "iCreativeStudio"
          }
}
        `
            }
          </script>

          <script type="application/ld+json" key="item-jsonld2" >
            {
              `{
          "@context": "https://schema.org/", 
          "@type": "BreadcrumbList", 
          "itemListElement": [{
            "@type": "ListItem", 
            "position": 1, 
            "name": "iCreativeStudio",
            "item": "https://icreativestudio.com/"  
          },{
            "@type": "ListItem", 
            "position": 2, 
            "name": "Digital Advertising Agency US | iCreativeStudio",
            "item": "https://icreativestudio.com/us-en/advertising-agency"  
          }]
        }        
        `
            }
          </script>
          <script type="application/ld+json" key="item-jsonld3" >
            {
              `{
          "@context": "https://schema.org",
          "@type": "FAQPage",
          "mainEntity": [{
            "@type": "Question",
            "name": "What services does your advertising agency in US offer?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "We offer a comprehensive range of advertising services, including but not limited to branding, digital marketing, social media management, content creation, media buying, and campaign strategy development."
            }
          },{
            "@type": "Question",
            "name": "Do you work with businesses of all sizes?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Yes, we work with businesses of all sizes, from startups and small businesses to larger enterprises. Our services are tailored to meet each client's unique needs and goals."
            }
          },{
            "@type": "Question",
            "name": "What makes your advertising agency different from others?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Our advertising agency stands out due to our creative approach, data-driven strategies, and commitment to delivering results. We combine artistic vision with analytical insights to create effective advertising campaigns."
            }
          },{
            "@type": "Question",
            "name": "Can you handle both online and offline advertising?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Absolutely. Our agency has expertise in both online and offline advertising channels. This includes digital platforms, social media, print media, television, radio, and more."
            }
          },{
            "@type": "Question",
            "name": "How long does it take to see results from an advertising campaign?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "The timeline for seeing results can vary based on factors such as the campaign's goals, the chosen channels, and the industry. We strive to provide a realistic timeframe during the strategy planning phase."
            }
          },{
            "@type": "Question",
            "name": "What is the cost of your advertising services?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "The cost of our services depends on the scope of the project, the services required, and the complexity of the campaign. We offer customized solutions to fit different budgets and will provide a detailed proposal after understanding your needs."
            }
          },{
            "@type": "Question",
            "name": "Can you help with ongoing campaign management and optimization?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Yes, we offer ongoing campaign management and optimization services to ensure that your advertising efforts continue to perform at their best and adapt to changing market dynamics."
            }
          },{
            "@type": "Question",
            "name": "How can I get started with your advertising agency in US?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Getting started is easy. Simply reach out to us through our contact page, and we'll schedule a consultation to discuss your needs and goals. From there, we'll work together to create a tailored advertising strategy."
            }
          }]
        }        
        `
            }
          </script>
          <title>Digital Advertising Agency US | iCreativeStudio</title>
          <meta name="robots" content="follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large" />
          <link rel="canonical" href="https://icreativestudio.com/us-en/advertising-agency" />
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="website" />
          <meta name="title" property="og:title" content="Digital Advertising Agency US | iCreativeStudio" />
          <meta name="description" property="og:description" content="Our advertising agency US illuminates your brand's potential, infusing every campaign with an artistic flair that commands attention." />
          <meta
            name="keywords"
            property="og:keyword"
            content="Advertising Agency"
          />
          <meta property="og:url" content="https://icreativesol.com/" />
          <meta property="og:site_name" content="iCreativeStudio" />
          <meta property="og:updated_time" content="2022-07-06T15:47:17+05:00" />
          <meta property="og:image" content="https://icreativestudio.com/static/media/ICS.d03a1cee52137330fc6a7900214145f4.svg" />
          <meta property="og:image:secure_url" content="https://icreativestudio.com/static/media/ICS.d03a1cee52137330fc6a7900214145f4.svg" />
          <meta property="og:image:width" content="512" />
          <meta property="og:image:height" content="512" />
          <meta property="og:image:alt" content="iCreativeStudio" />
          <meta property="og:image:type" content="image/png" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="Digital Advertising Agency US | iCreativeStudio" />
          <meta name="twitter:description" content="Our advertising agency US illuminates your brand's potential, infusing every campaign with an artistic flair that commands attention." />
          <meta name="twitter:image" content="https://icreativestudio.com/static/media/ICS.d03a1cee52137330fc6a7900214145f4.svg" />
          <meta name="twitter:label1" content="Written by" />
          <meta name="twitter:data1" content="admin" />
          <meta name="twitter:label2" content="Time to read" />
          <meta name="twitter:data2" content="4 minutes" />
        </Helmet>
      }

      <div style={{ display: isOpen ? "block" : "none" }}>
        <Suspense>
          <DrawerNav
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          />
        </Suspense>
      </div>
      <div style={{ display: isOpen ? "none" : "block" }}></div>
      <div style={{ display: !isOpen ? "block" : "none" }}>
        <Suspense>
          <HomeHeader
            backgroundColor={colors.black}
            textColor={colors.white}
            logo={ICS}
            toggle={setIsOpen}
            sizeHome
          />
        </Suspense>
        <Suspense>
          <BrandHeader
            title={WeDoAdvertising}
            subtitle={region == "ae" ? WeDoAdvertisingContent : WeDoAdvertisingUs}
            textColor={colors.white}
            content={[
              {
                content: WeDoAdvertising1
              },
              {
                content: WeDoAdvertising2
              },
              {
                content: WeDoAdvertising3
              },
              {
                content: WeDoAdvertising4
              },
            ]}
            backgroundColor={colors.black}
            logo={ICS}
            toggle={setIsOpen}
            button={
              {
                title: RequestAQuote, gradientHover: true,
                backgroundColor: colors.black, textColor: colors.white, borderColor: colors.white, link: region == "ae" ? "/ae-en/book-a-consultation" : "/us-en/book-a-consultation"
              }
            }
          />
        </Suspense>
        <Suspense>
          <BrandingConsultance
            title={NavigatingSuccess}
            content={region == "ae" ? NavigatingSuccessContent : NavigatingSuccessContentUs}
            size={'600px'}
            subTitles={
              region == "ae" ?

                [
                  {
                    subTitle: NavigatingSuccessHeading2
                  },
                  {
                    subTitle: NavigatingSuccessHeading3
                  },
                  {
                    subTitle: NavigatingSuccessHeading4
                  },
                ]
                :
                [
                  {
                    subTitle: NavigatingSuccessUsHeading1
                  },
                  {
                    subTitle: NavigatingSuccessUsHeading2
                  },
                  {
                    subTitle: NavigatingSuccessUsHeading3
                  },
                ]
            }
            EnableButton
            button1={
              {
                backgroundColor: colors.white,
                textColor: colors.black,
                gradientHover: true,
                borderColor: "transparent-border",
              }
            }
            button2={
              {
                title: ViewPortfolio,
                backgroundColor: colors.white,
                textColor: colors.white,
                gradientHover: true,
                borderColor: "transparent-border",
                link: region == "ae" ? '/ae-en/portfolio' : '/us-en/portfolio'
              }
            }
          />
        </Suspense>
        <Suspense>
          <Creativity
            title={region == "ae" ? OurAdvertisingServices : OurAdvertisingUs}
            subtitle={region == "ae" ? OurAdvertisingServicesContent : OurAdvertisingUsContent}
            textColor={colors.white}
            content={[
              {
                values: BarndingService1,
                title: OurAdvertisingServices1,
                subtitle: AdvertisingServicesContent1,
                link: region == "ae" ? '/ae-en/advertising-agency/ooh-advertising' : '/us-en/ooh-advertising'
              },
              {
                values: BarndingService2,
                title: OurAdvertisingServices2,
                subtitle: AdvertisingServicesContent2,
                link: region == "ae" ? '/ae-en/advertising-agency/print-advertising' : '/us-en/print-advertising-agency'
              },
              {
                values: BarndingService3,
                title: OurAdvertisingServices3,
                subtitle: AdvertisingServicesContent3,
                link: region == "ae" ? '/ae-en/advertising-agency/pr-agency-dubai' : '/us-en/pr-services'
              },
              {
                values: BarndingService4,
                title: OurAdvertisingServices4,
                subtitle: AdvertisingServicesContent4,
                link: region == "ae" ? '/ae-en/advertising-agency/influencer-marketing-agency-dubai' : "/us-en/influencer-marketing-strategy"
              },
              {
                values: BarndingService5,
                title: OurAdvertisingServices5,
                subtitle: AdvertisingServicesContent5,
                link: region == "ae" ? '/ae-en/advertising-agency/media-buying-agency-dubai' : '/us-en/media-buying-agency'
              },
            ]}
          />
        </Suspense>
        <Suspense>
          <Header
            title={ProjectsThatWeConquer}
            subtitle={region == "ae" ? ProjectsThatWeConquerContent : ProjectsThatWeConquerContentUs}
            textColor={colors.white}
            backgroundColor={"#151515"}
          />
          <CarousalComponent
            backgroundColor={"#151515"}
            textColor={colors.white}
            cards={[
              {
                image: Slide1,
                heading: 'join-Aok',
                link: region == "ae" ? '/ae-en/portfolio/joinaok' : '/us-en/portfolio/joinaok'
              },
              {
                image: Slide2,
                heading: 'Revme',
                link: region == "ae" ? '/ae-en/portfolio/revme' : '/us-en/portfolio/revme'
              },
              {
                image: Slide3,
                heading: 'Absolute Id',
                link: region == "ae" ? '/ae-en/portfolio/absolute-id' : "/us-en/portfolio/absolute-id"
              },
              {
                image: Slide4,
                heading: 'Childern of the Green Earth',
                link: region == "ae" ? '/ae-en/portfolio/children-of-the-earth' : '/us-en/portfolio/children-of-the-earth'
              },
              {
                image: Slide5,
                heading: 'Book Symphony',
                link: region == "ae" ? '/ae-en/portfolio/book-symphony' : "/us-en/portfolio/book-symphony"
              },
              {
                image: Slide9,
                heading: 'LGP Lawn',
                link: region == "ae" ? '/ae-en/portfolio/lgp-lawn' : "/us-en/portfolio/lgp-lawn"
              },
              {
                image: Slide6,
                heading: 'Hair At Home',
                link: region == "ae" ? '/ae-en/portfolio/hair-at-home/' : '/us-en/portfolio/hair-at-home/'
              },
              {
                image: Slide7,
                heading: 'Mobleys Gaming',
                link: region == "ae" ? '/ae-en/portfolio/mobleysgamingrvg/' : '/us-en/portfolio/mobleysgamingrvg/'
              },
              {
                image: Slide8,
                heading: 'The Life U Deverve 2Day',
                link: region == "ae" ? '/ae-en/portfolio/thelifeudeserve2day/' : '/us-en/portfolio/thelifeudeserve2day/'
              }
            ]}
          />
        </Suspense>
        <InfoCard
          title={PotentialAdvertising}
          subtitle={region == "ae" ? PotentialAdvertisingContent : PotentialAdvertisingContentUs}
          button={{
            title: region == "ae" ? ScheduleAnECoffee : GoTrustedAdAgency,
            backgroundColor: colors.transparent,
            simpleHover: true

          }}
          email={Email}
          textColor={colors.white}
        />
        <ServicesOffer
          title={region == "ae" ? IgniteYourBrand : IgniteYourBrandJourney}
          data={
            region == "ae" ?
              {
                c1r1Heading: PerspectiveOnSuccess,
                c1r1Content: PerspectiveOnSuccessContent,

                c1r2Heading: SettingDataDrivenTrends,
                c1r2Content: SettingDataDrivenTrendsContent,
                // second
                c2r1Heading: IlluminatingCreativity,
                c2r1Content: IlluminatingCreativityContent,

                c2r2Heading: ElevateGrowth,
                c2r2Content: ElevateGrowthContent,
              } :
              {
                c1r1Heading: PerspectiveSuccessUs,
                c1r1Content: PerspectiveSuccessUsContent,

                c1r2Heading: SettingTrendsUs,
                c1r2Content: SettingTrendsUsContent,
                // second
                c2r1Heading: IlluminatingCreativityUs,
                c2r1Content: IlluminatingCreativityUsContent,

                c2r2Heading: ElevateBusinessGrowthUs,
                c2r2Content: ElevateBusinessGrowthUsContent,
              }
          }
        />


        <SimilarBlogs
          backgroundColor={colors.black}
          textColor={colors.white}
          title={InSightBlogs}
          content={blogs}
          button={{
            title: ViewMore,
            backgroundColor: colors.black,
            textColor: colors.white,
            gradientHover: true,
            borderColor: "gradient",
          }}
          link={region == "ae" ? "/ae-en/creative-blogs" : "/us-en/creative-blogs"}
        />
        <FAQs
          heading={FAQ}
          accordionsArray={
            region == "ae" ?
              [
                {
                  title: AdvertisingQuestion1, content: [{
                    simpleText: AdvertisingAnswer1
                  }]
                },
                {
                  title: AdvertisingQuestion2, content: [{
                    simpleText: AdvertisingAnswer2
                  }]
                },
                {
                  title: AdvertisingQuestion3, content: [{
                    simpleText: AdvertisingAnswer3
                  }]
                },
                {
                  title: AdvertisingQuestion4, content: [{
                    simpleText: AdvertisingAnswer4
                  }]
                },
                {
                  title: AdvertisingQuestion5, content: [{
                    simpleText: AdvertisingAnswer5
                  }]
                },
                {
                  title: AdvertisingQuestion6, content: [{
                    simpleText: AdvertisingAnswer6
                  }]
                },
                {
                  title: AdvertisingQuestion7, content: [{
                    simpleText: AdvertisingAnswer7
                  }]
                },
                {
                  title: AdvertisingQuestion8, content: [{
                    simpleText: AdvertisingAnswer8
                  }]
                },

              ] :
              [
                {
                  title: AdvertisingUsQuestion1, content: [{
                    simpleText: AdvertisingUsAnswer1
                  }]
                },
                {
                  title: AdvertisingUsQuestion2, content: [{
                    simpleText: AdvertisingUsAnswer2
                  }]
                },
                {
                  title: AdvertisingUsQuestion3, content: [{
                    simpleText: AdvertisingUsAnswer3
                  }]
                },
                {
                  title: AdvertisingUsQuestion4, content: [{
                    simpleText: AdvertisingUsAnswer4
                  }]
                },
                {
                  title: AdvertisingUsQuestion5, content: [{
                    simpleText: AdvertisingUsAnswer5
                  }]
                },
                {
                  title: AdvertisingUsQuestion6, content: [{
                    simpleText: AdvertisingUsAnswer6
                  }]
                },
                {
                  title: AdvertisingUsQuestion7, content: [{
                    simpleText: AdvertisingUsAnswer7
                  }]
                },
                {
                  title: AdvertisingUsQuestion8, content: [{
                    simpleText: AdvertisingUsAnswer8
                  }]
                },

              ]
          }
        />
        <CaseStudyFooter
          title={WeOnlySpeak}
          subtitle={Liketotalk}
          backgroundColor={colors.black}
          textColor={colors.white}
          button={{
            title: SendAMessage,
            backgroundColor: colors.black,
            textColor: colors.white,
            gradientHover: true,
            borderColor: "gradient",
          }}
        />
        <BrandingFooter
          heading={"Let’s make it happen"}
          textColor={colors.gray}
          Email={EmailValue}
          PhoneNumberAE={PhoneNumberAE}
          PhoneNumberUS={PhoneNumberUS}
          Address={Address}
          AddressAe={AddressAe}
          title={Newsletter}
          subTitle={NewsletterContent}
          CopyRight={CopyRight}
          button={{
            title: 'Submit',
            textColor: colors.white,
            gradientHover: true,
            borderColor: "gradient",
            borderRadius: '200px'
          }}
          SocialTitle={FollowUs}
          content={[
            {
              content: Blogs,
              link: region == "ae" ? '/ae-en/creative-blogs' : "/us-en/creative-blogs"
            },
            {
              content: Portfolio,
              link: region == "ae" ? '/ae-en/portfolio' : "/us-en/portfolio"
            },
            {
              content: WhoWeAre,
              link: region == "ae" ? '/ae-en/about-us' : '/us-en/about-us'
            }, {
              content: Experties,
              link: region == "ae" ? '/ae-en/expertise' : '/us-en/expertise'
            },
            {
              content: ContactUs,
              link: region == "ae" ? '/ae-en/contact-us' : '/us-en/contact-us'
            }
          ]}
        />
      </div>

    </>
  )
})

export default Advertising;