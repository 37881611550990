import React, { memo, Suspense, useState, useEffect } from "react";
import { colors } from "../../utils/colors";
import { content } from "../../content"
import SimilarBlogs from "../../components/modules/SimilarBlog";
import ICS from "../../assets/images/ICS.webp"
import Slide1 from "../../assets/images/join-Aok-slide.webp";
import Slide2 from "../../assets/images/Revme-slides.webp";
import Slide3 from "../../assets/images/Absolute-ID-slide.webp";
import Slide4 from "../../assets/images/children-of-green-earth-slide.webp";
import Slide5 from "../../assets/images/book-symphony-slide.webp";
import Slide6 from "../../assets/images/hair-at-home-slide.webp";
import Slide7 from "../../assets/images/mobiley-game-slide.webp";
import Slide8 from "../../assets/images/desire-2day-slide.webp";
import Slide9 from "../../assets/images/LGP-Lawn-care-slide.webp";

import BrandHeader from "../../components/modules/BrandHeader";
import Header from "../../components/modules/Header";
import InfoCard from "../../components/modules/InfoCard";
import DrawerNav from "../../components/modules/DrawerNav";
import FAQs from "../../components/modules/FAQs";
import CarousalComponent from "../../components/modules/CarousalContent";
import ServicesOffer from "../../components/modules/ServicesOffer";
import BrandingConsultance from "../../components/modules/BrandingConultancy";
import CaseStudyFooter from "../../components/modules/CaseStudyFooter";
import BrandingFooter from "../../components/modules/BrandingFooter";
import Creativity from "../../components/modules/Creativity";
import HomeHeader from "../../components/modules/HomeHeader";


import { GET_ALL_BLOGS } from "../../services/queries"
 import { useSelector } from 'react-redux';
import { useLazyQuery } from "@apollo/client"
import Helmet from "react-helmet"

const Marketing = memo(() => {
  useEffect(() => {
    window.scrollTo(0, 0);
}, [])
  const region = useSelector((state) => state.region.region);
  const [isOpen, setIsOpen] = useState(false);
  const [blogs, setBlogs] = React.useState([]);
  const [endCursor, setEndCursor] = React.useState(null);
  const [hasNextPage, setHasNextPage] = React.useState(false);
  const [posts, { data: users, loading }] = useLazyQuery(GET_ALL_BLOGS(endCursor))
  const { WeDoMarketing,
    WeDoMarketing1, WeDoMarketing2, WeDoMarketing3, WeDoMarketing4,
    WeDoMarketingContent,WeDoMarketingContentUs,
    LetRoll,MarketingAgencyUs,MarketingAgencyUsContent,
    MarketingAgency, MarketingAgencyContent,
    PotentialAdvertising, PotentialAdvertisingContent, TrustedAdAgency,
    OurMarketingServices, OurMarketingServicesContent,
    OurMarketingServices1, MarketingServicesContent1,
    OurMarketingServices2, MarketingServicesContent2,
    OurMarketingServices3, MarketingServicesContent3,
    OurMarketingServices4, MarketingServicesContent4,
    OurMarketingServices5, MarketingServicesContent5,
    MarketingGame, MarketingGameContent,
    MarketingAgencyUsHeading2,
    MarketingAgencyUsHeading3,
    MarketingAgencyUsHeading4,
    AddOnns, BrandAwareness, BrandAwarenessConetnt,
    PerformanceMarketing, PerformanceMarketingContent,
    DigitalMarketing, DigitalMarketingContent,
    ContentMarketing, ContentMarketingContent,
    InboundMarketingUS,InboundMarketingUSContent,
    DigitalMarketingUs,DigitalMarketingUsContent,
    PerformanceMarketingUs,PerformanceMarketingUsContent,
    ContentMarketingUs,ContentMarketingUsContent,
    ViewPortfolio,
    MarketingAgencyContentHeading2,
    MarketingAgencyContentHeading3,
    MarketingAgencyContentHeading4,
    MarketingAgencyContentHeading5,
    MarketingQuestion1, MarketingQuestion2,
    MarketingQuestion3, MarketingQuestion4,
    MarketingAnswer1, MarketingAnswer2,
    MarketingAnswer3, MarketingAnswer4,
    MarketingUsQuestion1, MarketingUsQuestion2,
    MarketingUsQuestion3, MarketingUsQuestion4,
    MarketingUsAnswer1, MarketingUsAnswer2,
    MarketingUsAnswer3, MarketingUsAnswer4,
    BarndingService1, BarndingService2, BarndingService3, BarndingService4,
    BarndingService5, RequestAQuote, Email, FAQ, ViewMore, InSightBlogs, WeOnlySpeak,
    Liketotalk, SendAMessage, Blogs, Portfolio, WhoWeAre, Experties, ContactUs, PhoneNumber, EmailValue,
    Address, AddressAe, Newsletter, NewsletterContent, CopyRight, FollowUs,ScheduleAnECoffee,
    PhoneNumberAE,PhoneNumberUS } = content;
  useEffect(() => {

    getBlogs()

  }, [hasNextPage])

  const getBlogs = () => {
    posts().then((res) => {
      let blog = [...blogs]
      res.data.posts.edges.map((item, index) => {
        blog.push({
          image: item.node.featuredImage.node.sourceUrl,
          title: `Blog - ${formatDate(item.node.date)}`,
          subtitle: item.node.title,
          link: item.node.uri,
          id: item.node.id,
          key: index
        })
      })

      setEndCursor(res.data.posts.pageInfo.endCursor)
      setBlogs(blog)
      setHasNextPage(false)

    }).catch((err) => {



    })
  }

  function formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('/');
  }
  return (
    <>
      {region == "ae" ?
         <Helmet>
         <script type="application/ld+json" key="item-jsonld1" >
           {
             `{
               "@context": "https://schema.org",
               "@type": "ProfessionalService",
               "name": "Marketing and Strategy Services in Dubai | iCreativeStudio",
               "url": "https://icreativestudio.com/ae-en/marketing-and-strategy/",
             "description": "iCreativeStudio is a leading Creative Agency in Dubai, working with brands across the Mena, providing brand marketing and strategy services.",
               "brand": {
                 "@type": "Brand",
                 "name": "iCreativeStudio"
               }
     }`
           }
         </script>
 
         <script type="application/ld+json" key="item-jsonld2" >
           {
             `{
               "@context": "https://schema.org/", 
               "@type": "BreadcrumbList", 
               "itemListElement": [{
                 "@type": "ListItem", 
                 "position": 1, 
                 "name": "iCreativeStudio",
                 "item": "https://icreativestudio.com/ae-en"  
               },{
                 "@type": "ListItem", 
                 "position": 2, 
                 "name": "Marketing and Strategy Services in Dubai | iCreativeStudio",
                 "item": "https://icreativestudio.com/ae-en/marketing-and-strategy/"  
               }]
             }
             `
           }
         </script>
 
         <script type="application/ld+json" key="item-jsonld3" >
           {
             `{
               "@context": "https://schema.org",
               "@type": "FAQPage",
               "mainEntity": [{
                 "@type": "Question",
                 "name": "What services are included in Brand Marketing and Strategy?",
                 "acceptedAnswer": {
                   "@type": "Answer",
                   "text": "Brand Marketing is a broad term for all types of marketing and channels required to grow a brand. As a top-notch marketing agency, we understand the kind of marketing and strategy required for specific businesses and industries; still, our brand marketing services kick off from crafting an overall marketing strategy first and then breaking it down to inbound and outbound marketing, content marketing, digital marketing, B2B and B2C marketing as well."
                 }
               },{
                 "@type": "Question",
                 "name": "Can a brand marketing agency help my B2B brand to grow?",
                 "acceptedAnswer": {
                   "@type": "Answer",
                   "text": "As a leading brand marketing agency Dubai, we have helped many B2B brands to reach their target through our brand marketing strategy. Our process for elevating B2B brands begins by defining the target audience first; then, we develop a strong brand identity and craft content that best aligns with the target audience."
                 }
               },{
                 "@type": "Question",
                 "name": "What is content marketing, and how can it help our brand grow?",
                 "acceptedAnswer": {
                   "@type": "Answer",
                   "text": "Content marketing is a broad term for creating and distributing content that includes blogs, articles, and videos that add to brand credibility and brand awareness. Research has shown that 78% of consumers prefer getting to know a company by reading an article rather than seeing an ad. So, by leveraging paid ads, your brand can use content marketing to nurture leads and generate results."
                 }
               },{
                 "@type": "Question",
                 "name": "How soon can paid advertisement help me generate leads?",
                 "acceptedAnswer": {
                   "@type": "Answer",
                   "text": "Paid advertisements mean reaching out to your target audience through target-based advertising using channels, including P2P advertising, Social Media advertising, and Search Engine Marketing. It can generate leads immediately or sometimes requires some time, depending on a number of factors, such as the industry, target audience, landing pages, or budget."
                 }
               }]
             }
             `
           }
         </script>
         <title>Marketing and Strategy Services in Dubai | iCreativeStudio</title>
         <meta name="robots" content="follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large" />
         <link rel="canonical" href="https://icreativestudio.com/ae-en/marketing-and-strategy/" />
         <meta property="og:locale" content="en_US" />
         <meta property="og:type" content="website" />
         <meta name="title" property="og:title" content="Marketing and Strategy Services in Dubai | iCreativeStudio" />
         <meta name="description" property="og:description" content="iCreativeStudio is a leading Creative Agency in Dubai, working with brands across the Mena, providing brand marketing and strategy services." />
         <meta
           name="keywords"
           property="og:keyword"
           content="Marketing and Strategy"
         />
         <meta property="og:url" content="https://icreativesol.com/" />
         <meta property="og:site_name" content="iCreativeStudio" />
         <meta property="og:updated_time" content="2022-07-06T15:47:17+05:00" />
         <meta property="og:image" content="https://icreativestudio.com/static/media/ICS.d03a1cee52137330fc6a7900214145f4.svg" />
         <meta property="og:image:secure_url" content="https://icreativestudio.com/static/media/ICS.d03a1cee52137330fc6a7900214145f4.svg" />
         <meta property="og:image:width" content="512" />
         <meta property="og:image:height" content="512" />
         <meta property="og:image:alt" content="iCreativeStudio" />
         <meta property="og:image:type" content="image/png" />
         <meta name="twitter:card" content="summary_large_image" />
         <meta name="twitter:title" content="Marketing and Strategy Services in Dubai | iCreativeStudio" />
         <meta name="twitter:description" content="iCreativeStudio is a leading Creative Agency in Dubai, working with brands across the Mena, providing brand marketing and strategy services." />
         <meta name="twitter:image" content="https://icreativestudio.com/static/media/ICS.d03a1cee52137330fc6a7900214145f4.svg" />
         <meta name="twitter:label1" content="Written by" />
         <meta name="twitter:data1" content="admin" />
         <meta name="twitter:label2" content="Time to read" />
         <meta name="twitter:data2" content="4 minutes" />
       </Helmet> :
       <Helmet>
       <script type="application/ld+json" key="item-jsonld1" >
         {
           `{
            "@context": "https://schema.org",
            "@type": "ProfessionalService",
            "name": "Marketing Services Agency USA | iCreativeStudio",
            "url": "https://icreativestudio.com/marketing-services",
          "description": "iCreativeStudio is a leading marketing services agency in US with a proven marketing strategy record in the US and North America region",
            "brand": {
              "@type": "Brand",
              "name": "iCreativeStudio"
            }
  }`
         }
       </script>

       <script type="application/ld+json" key="item-jsonld2" >
         {
           `{
            "@context": "https://schema.org/", 
            "@type": "BreadcrumbList", 
            "itemListElement": [{
              "@type": "ListItem", 
              "position": 1, 
              "name": "iCreativeStudio",
              "item": "https://icreativestudio.com/"  
            },{
              "@type": "ListItem", 
              "position": 2, 
              "name": "Marketing Services Agency USA | iCreativeStudio",
              "item": "https://icreativestudio.com/us-en/marketing-services"  
            }]
          }          
           `
         }
       </script>
       <script type="application/ld+json" key="item-jsonld3" >
         {
           `{
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [{
              "@type": "Question",
              "name": "What services are included in Brand Marketing?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Brand Marketing is a broad term for all types of marketing and channels required to grow a brand. As a top-notch marketing agency, we understand the kind of marketing required for specific businesses and industries; still, our brand marketing services kick off from crafting an overall marketing strategy first and then breaking it down to inbound and outbound marketing, content marketing, digital marketing, B2B and B2C marketing as well."
              }
            },{
              "@type": "Question",
              "name": "Can a brand marketing agency help my B2B brand to grow?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "As a leading brand marketing agency US, we have helped many B2B brands to reach their target through our brand marketing strategy. Our process for elevating B2B brands begins by defining the target audience first; then, we develop a strong brand identity and craft content that best aligns with the target audience."
              }
            },{
              "@type": "Question",
              "name": "What is content marketing, and how can it help our brand grow?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Content marketing is a broad term for creating and distributing content that includes blogs, articles, and videos that add to brand credibility and brand awareness. Research has shown that 78% of consumers prefer getting to know a company by reading an article rather than seeing an ad. So, by leveraging paid ads, your brand can use content marketing to nurture leads and generate results."
              }
            },{
              "@type": "Question",
              "name": "How soon can paid advertisement help me generate leads?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Paid advertisements mean reaching out to your target audience through target-based advertising using channels, including P2P advertising, Social Media advertising, and Search Engine Marketing. It can generate leads immediately or sometimes requires some time, depending on a number of factors, such as the industry, target audience, landing pages, or budget."
              }
            }]
          }          
           `
         }
       </script>
       <title>Marketing Services Agency USA | iCreativeStudio</title>
        <meta name="robots" content="follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large" />
        <link rel="canonical" href="https://icreativestudio.com/us-en/marketing-services" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta name="title" property="og:title" content="Marketing Services Agency USA | iCreativeStudio" />
        <meta name="description" property="og:description" content="iCreativeStudio is a leading marketing services agency in US with a proven marketing strategy record in the US and North America region" />
        <meta
          name="keywords"
          property="og:keyword"
          content="Marketing Services"
        />
        <meta property="og:url" content="https://icreativesol.com/" />
        <meta property="og:site_name" content="iCreativeStudio" />
        <meta property="og:updated_time" content="2022-07-06T15:47:17+05:00" />
        <meta property="og:image" content="https://icreativestudio.com/static/media/ICS.d03a1cee52137330fc6a7900214145f4.svg" />
        <meta property="og:image:secure_url" content="https://icreativestudio.com/static/media/ICS.d03a1cee52137330fc6a7900214145f4.svg" />
        <meta property="og:image:width" content="512" />
        <meta property="og:image:height" content="512" />
        <meta property="og:image:alt" content="iCreativeStudio" />
        <meta property="og:image:type" content="image/png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Marketing Services Agency USA | iCreativeStudio" />
        <meta name="twitter:description" content="iCreativeStudio is a leading marketing services agency in US with a proven marketing strategy record in the US and North America region" />
        <meta name="twitter:image" content="https://icreativestudio.com/static/media/ICS.d03a1cee52137330fc6a7900214145f4.svg" />
        <meta name="twitter:label1" content="Written by" />
        <meta name="twitter:data1" content="admin" />
        <meta name="twitter:label2" content="Time to read" />
        <meta name="twitter:data2" content="4 minutes" />

     </Helmet>
    }
            <Suspense>
      <div style={{ display: isOpen ? "block" : "none" }}>
          <DrawerNav
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          />
      </div>
      <div style={{ display: isOpen ? "none" : "block" }}></div>
      <div style={{ display: !isOpen ? "block" : "none" }}>
          <HomeHeader
            backgroundColor={colors.black}
            textColor={colors.white}
            logo={ICS}
            toggle={setIsOpen}
            sizeHome
          />
          <BrandHeader
            title={WeDoMarketing}
            subtitle={region == "ae"?WeDoMarketingContent:WeDoMarketingContentUs}
            textColor={colors.white}
            content={[
              
              {
                content: WeDoMarketing2
              },
              {
                content: WeDoMarketing3
              },
              {
                content: WeDoMarketing1
              },
            ]}
            backgroundColor={colors.black}
            logo={ICS}
            toggle={setIsOpen}
            button={
              { title: region == "ae"?RequestAQuote:LetRoll,  gradientHover: true,
                backgroundColor: colors.black, textColor: colors.white, borderColor: colors.white, link: region == "ae" ? "/ae-en/book-a-consultation":"/us-en/book-a-consultation" }
            }
          />
          <BrandingConsultance
            title={region == "ae" ? MarketingAgency:MarketingAgencyUs}
            content={region == "ae" ? MarketingAgencyContent:MarketingAgencyUsContent}
            size={'700px'}
            subTitles={
              region == "ae"?
              [
                {
                  subTitle:MarketingAgencyContentHeading2
                },
                {
                  subTitle:MarketingAgencyContentHeading3
                },
                {
                  subTitle:MarketingAgencyContentHeading4
                },
                {
                  subTitle:MarketingAgencyContentHeading5
                }
              ]:
              [
                {
                  subTitle:MarketingAgencyUsHeading2
                },
                {
                  subTitle:MarketingAgencyUsHeading3
                },
                {
                  subTitle:MarketingAgencyUsHeading4
                }
              ]
            }
            EnableButton
            button1={
              {
                backgroundColor: colors.white,
                textColor: colors.black,
                gradientHover: true,
                borderColor: "transparent-border",
              }
            }
            button2={
              {
                title: ViewPortfolio,
                backgroundColor: colors.white,
                textColor: colors.white,
                link: region == "ae" ? '/ae-en/portfolio' : '/us-en/portfolio'
              }
            }
          />
          <Creativity
            title={OurMarketingServices}
            subtitle={OurMarketingServicesContent}
            textColor={colors.white}
            content={[
              {
                values: BarndingService1,
                title: OurMarketingServices1,
                subtitle: MarketingServicesContent1,
                link: region == "ae" ?  '/ae-en/marketing-and-strategy/inbound-marketing' : '/us-en/inbound-marketing-services'
              },
              {
                values: BarndingService2,
                title: OurMarketingServices2,
                subtitle: MarketingServicesContent2,
                link: region == "ae" ? '/ae-en/marketing-and-strategy/content-marketing-agency-dubai' : '/us-en/content-marketing-services'
              },
              {
                values: BarndingService3,
                title: OurMarketingServices3,
                subtitle: MarketingServicesContent3,
                link: region == "ae" ? '/ae-en/marketing-and-strategy/digital-marketing-agency-dubai' : "/us-en/digital-marketing-services"
              },
              {
                values: BarndingService4,
                title: OurMarketingServices4,
                subtitle: MarketingServicesContent4,
                link: region == "ae" ?  '/ae-en/marketing-and-strategy/performance-marketing' : "/us-en/performance-marketing"
              },
              {
                values: BarndingService5,
                title: OurMarketingServices5,
                subtitle: MarketingServicesContent5,
                link: region == "ae" ? '/ae-en/marketing-and-strategy/copywriting-services-dubai' : '/us-en/copywriting-services'
              },
            ]}
          />

          <Header
            title={MarketingGame}
            subtitle={MarketingGameContent}
            textColor={colors.white}
            backgroundColor={"#151515"}
          />
          <CarousalComponent
            backgroundColor={"#151515"}
            textColor={colors.white}
            cards={[
              {
                image: Slide1,
                heading: 'join-Aok',
                link: region == "ae" ? '/ae-en/portfolio/joinaok' : '/us-en/portfolio/joinaok'
              },
              {
                image: Slide2,
                heading: 'Revme',
                link: region == "ae" ? '/ae-en/portfolio/revme' : '/us-en/portfolio/revme'
              },
              {
                image: Slide3,
                heading: 'Absolute Id',
                link: region == "ae" ? '/ae-en/portfolio/absolute-id' :"/us-en/portfolio/absolute-id"
              },
              {
                image: Slide4,
                heading: 'Childern of the Green Earth',
                link: region == "ae" ? '/ae-en/portfolio/children-of-the-earth' : '/us-en/portfolio/children-of-the-earth'
              },
              {
                image: Slide5,
                heading: 'Book Symphony',
                link: region == "ae" ? '/ae-en/portfolio/book-symphony' : "/us-en/portfolio/book-symphony"
              },
              {
                image: Slide9,
                heading: 'LGP Lawn',
                link: region == "ae" ? '/ae-en/portfolio/lgp-lawn' : "/us-en/portfolio/lgp-lawn"
              },
              {
                image: Slide6,
                heading: 'Hair At Home',
                link: region == "ae" ? '/ae-en/portfolio/hair-at-home/': '/us-en/portfolio/hair-at-home/'
              },
              {
                image: Slide7,
                heading: 'Mobleys Gaming',
                link: region == "ae" ? '/ae-en/portfolio/mobleysgamingrvg/' : '/us-en/portfolio/mobleysgamingrvg/'
              },
              {
                image: Slide8,
                heading: 'The Life U Deverve 2Day',
                link: region == "ae" ? '/ae-en/portfolio/thelifeudeserve2day/': '/us-en/portfolio/thelifeudeserve2day/'
              }
            ]}
          />
        <InfoCard
          title={PotentialAdvertising}
          subtitle={PotentialAdvertisingContent}
          button={{
            title: ScheduleAnECoffee,
            backgroundColor: colors.transparent,
            simpleHover:true
          }}
          email={Email}
          textColor={colors.white}
        />
          <ServicesOffer
            title={AddOnns}
            data={
              region == "ae" ?
              {
              c1r1Heading: BrandAwareness,
              c1r1Content: BrandAwarenessConetnt,

              c1r2Heading: DigitalMarketing,
              c1r2Content: DigitalMarketingContent,
              // second
              c2r1Heading: PerformanceMarketing,
              c2r1Content: PerformanceMarketingContent,

              c2r2Heading: ContentMarketing,
              c2r2Content: ContentMarketingContent,
            }
            : {
              c1r1Heading: InboundMarketingUS,
              c1r1Content: InboundMarketingUSContent,

              c1r2Heading: DigitalMarketingUs,
              c1r2Content: DigitalMarketingUsContent,
              // second
              c2r1Heading: PerformanceMarketingUs,
              c2r1Content: PerformanceMarketingUsContent,

              c2r2Heading: ContentMarketingUs,
              c2r2Content: ContentMarketingUsContent,
            }
          }
          />
          <SimilarBlogs
            backgroundColor={colors.black}
            textColor={colors.white}
            title={InSightBlogs}
            content={blogs}
            button={{
              title: ViewMore,
              backgroundColor: colors.black,
              textColor: colors.white,
              gradientHover: true,
              borderColor: "gradient",
            }}
            link={ region == "ae"? "/ae-en/creative-blogs":"/us-en/creative-blogs"}

          />
      
          <FAQs
            heading={FAQ}
            accordionsArray={
              region == "ae"?[
              {
                title: MarketingQuestion1, content: [{
                  simpleText: MarketingAnswer1
                }]
              },
              {
                title: MarketingQuestion2, content: [{
                  simpleText: MarketingAnswer2
                }]
              },
              {
                title: MarketingQuestion3, content: [{
                  simpleText: MarketingAnswer3
                }]
              },
              {
                title: MarketingQuestion4, content: [{
                  simpleText: MarketingAnswer4
                }]
              }
            ]:[
              {
                title: MarketingUsQuestion1, content: [{
                  simpleText: MarketingUsAnswer1
                }]
              },
              {
                title: MarketingUsQuestion2, content: [{
                  simpleText: MarketingUsAnswer2
                }]
              },
              {
                title: MarketingUsQuestion3, content: [{
                  simpleText: MarketingUsAnswer3
                }]
              },
              {
                title: MarketingUsQuestion4, content: [{
                  simpleText: MarketingUsAnswer4
                }]
              }
            ]}
          />
          <CaseStudyFooter
            title={WeOnlySpeak}
            subtitle={Liketotalk}
            backgroundColor={colors.black}
            textColor={colors.white}
            button={{
              title: SendAMessage,
              backgroundColor: colors.black,
              textColor: colors.white,
              gradientHover: true,
              borderColor: "gradient",
            }}
          />
      
        <BrandingFooter
        heading={"Let’s make it happen"}
        textColor={colors.gray}
        Email={EmailValue}
        Address={Address}
        PhoneNumberAE={PhoneNumberAE}
        PhoneNumberUS={PhoneNumberUS}
        AddressAe={AddressAe}
        title={Newsletter}
        subTitle={NewsletterContent}
        CopyRight={CopyRight}
        button={{
          title: 'Submit',
          textColor: colors.white,
          gradientHover: true,
          borderColor: "gradient",
          borderRadius:'200px'
        }}
        SocialTitle={FollowUs}
        content={[
            {
              content: Blogs,
              link:region == "ae" ? '/ae-en/creative-blogs': "/us-en/creative-blogs"
            },
            {
              content: Portfolio,
              link:region == "ae" ? '/ae-en/portfolio': "/us-en/portfolio"
            },
            {
              content: WhoWeAre,
              link:region == "ae" ? '/ae-en/about-us': '/us-en/about-us'
            }, {
              content: Experties,
              link:region == "ae" ? '/ae-en/expertise': '/us-en/expertise'
            },
            {
              content: ContactUs,
              link:region == "ae" ? '/ae-en/contact-us': '/us-en/contact-us'
            }
          ]}
        />
      </div>
      </Suspense>

    </>
  )
})

export default Marketing;