import React, { memo } from "react";
import { Menu } from "react-feather";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useState } from "react";
import { DropdownButton, Form } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import {Col} from 'react-bootstrap'
import "./index.css"
import { useDispatch } from 'react-redux';
import { AddingRegion } from '../../../redux/RegionSlice';

const TopHeader = memo((props) => {
  const { image, toggle } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const region = useSelector((state) => state.region.region);
  const [country, setCountry] = useState();
  const storedOption = localStorage.getItem('selectedOption');



  console.log(region, "new Region ")
  // const handleSelect=(eventKey)=>{
  //   //  const { value } = event.target;
     
  //    dispatch(AddingRegion(eventKey));
  //    {eventKey == "ae"? navigate('/ae-en'): navigate('/us-en')}
  //   //  window.location.reload();
  // }
  const handleNewRegion=(e)=>{
     if(e.target.value !== "Region"){
      dispatch(AddingRegion(e.target.value));
      // localStorage.setItem('selectedOption', e.target.value);
      {e.target.value == "ae" ? navigate('/ae-en'): navigate('/us-en')} 
     }
      }
    
  return (
      <div className=" d-flex px-0 justify-content-between align-items-center">

                 <img 
              src={image}
              onClick={() => {
                navigate(region == "ae"? "/ae-en" :"/us-en", { replace: true });
              }}
              style={{ cursor: "pointer" }}
              width="80px"
              alt="ICS-LOGO"
              loading="lazy"
              height="100%"
              />
            
             <div className="d-flex">
              <div style={{ marginRight:'20px'}}>
              {/* <DropdownButton
      alignRight
      title="Region"
      id="dropdown-menu-align-right"
      onSelect={handleSelect}
        >
              <Dropdown.Item eventKey="ae">UAE</Dropdown.Item>
              <Dropdown.Item eventKey="us">North America</Dropdown.Item>
      </DropdownButton> */}
      <Form.Select aria-label="Default select example" onChange={handleNewRegion} value={region}>
      <option selected>Region</option>
      <option value="ae">UAE</option>
      <option value="North America">North America</option>
    </Form.Select>
              </div>
              <div>
                
            <Menu
              onClick={() => toggle(true)}
              size={38}
              color={window.location.pathname === "/ae-en/contact-us"  || window.location.pathname === "/us-en/contact-us"  ? "#000" : "#FFF"}
              style={{ cursor: "pointer" }}
            />
              </div>
             

             </div>
            
    
      </div>
  );
});

export default TopHeader;
