import React, { memo, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./style.css";

const Heading = memo((props) => {

    const {redUnderline, heading, color, fontSize,textShadow, fontFamily, size,greenUnderline, underline, maxNumberOfLines, nomargin, capitalize, bold, bolder, onClick, index } = props;

    const navigate = useNavigate()
    const [gradientWords, setGradientWords] = useState("")
    const [routeWords, setRouteWords] = useState("")
    const [beforeGradientWords, setBeforeGradientWords] = useState("")
    const [afterGradientWords, setAfterGradientWords] = useState("")


    var Tag,
        ClassName = "";

    if (!size) {
        ClassName = "display-5";
    }

    if (size === "xxs") {
        Tag = "p";
        ClassName = ClassName + " " + fontFamily
    } else if (size === "xs") {
        Tag = "h5";
        ClassName = ClassName + " " + fontFamily
    } else if (size === "sm") {
        Tag = "h4";
        ClassName = ClassName + " " + fontFamily

    } else if (size === "md") {
        Tag = "h3";
        ClassName = ClassName + " " + fontFamily

    } else if (size === "lg") {
        Tag = "h2";
        ClassName = ClassName + " " + fontFamily

    } else if (size === "xxl") {
        Tag = "h1";
        ClassName = ClassName + " " + fontFamily
    }

    else {
        Tag = "h1";
        ClassName = ClassName + " " + fontFamily
    }

    if (underline) {
        ClassName = ClassName + " " + "text-underline-hover";
    }

    if (maxNumberOfLines) {
        ClassName = ClassName + " " + "multine-ellipsis-" + maxNumberOfLines;
    }

    if (nomargin) {
        ClassName = ClassName + " " + "m-0";
    }

    if (capitalize) {
        ClassName = ClassName + " " + "text-capitalize"
    }

    if (bold) {
        ClassName = ClassName + " " + "font-bold"
    } else if (bolder) {
        ClassName = ClassName + " " + "font-bolder"
    }

    // const textSegments = heading.split("<br><br>").map((segment, index) => (
    //     <React.Fragment key={index}>
    //       {index > 0 && <br />}
    //       {segment}
    //     </React.Fragment>
    //   ));


    useEffect(() => {
        if (heading?.includes("<grad>")) {

            let splitedHeading = heading.split(" ");
            let startingIndex = splitedHeading.indexOf("<grad>");
            let endingIndex = splitedHeading.indexOf("<grad>", splitedHeading.indexOf("<grad>") + 1);
            let gradientWord = ""
            let beforeGradient = ""
            let afterGradient = ""


            for (let i = 0; i <= splitedHeading.length - 1; i++) {
                if (i >= startingIndex + 1 && i <= endingIndex - 1) {
                    gradientWord += splitedHeading[i] + " "

                }
                else if (i < startingIndex) {
                    beforeGradient += splitedHeading[i] + " "

                }
                else if (i > endingIndex) {
                    afterGradient += splitedHeading[i] + " "
                }
            }

            setGradientWords(gradientWord)
            setBeforeGradientWords(beforeGradient)
            setAfterGradientWords(afterGradient)

        }
        else if (heading?.includes("<line>")) {

            let splitedHeading = heading.split(" ");
            let startingIndex = splitedHeading.indexOf("<line>");
            let endingIndex = splitedHeading.indexOf("<line>", splitedHeading.indexOf("<line>") + 1);
            let gradientWord = ""
            let beforeGradient = ""
            let afterGradient = ""


            for (let i = 0; i <= splitedHeading.length - 1; i++) {
                if (i >= startingIndex + 1 && i <= endingIndex - 1) {
                    gradientWord += splitedHeading[i] + " "

                }
                else if (i < startingIndex) {
                    beforeGradient += splitedHeading[i] + " "

                }
                else if (i > endingIndex) {
                    afterGradient += splitedHeading[i] + " "
                }
            }

            setGradientWords(gradientWord)
            setBeforeGradientWords(beforeGradient)
            setAfterGradientWords(afterGradient)

        }

        else if (heading?.includes("<link>")) {

            let splitedHeading = heading.split(" ");
            let startingIndex = splitedHeading.indexOf("<link>");
            let endingIndex = splitedHeading.indexOf("<link>", splitedHeading.indexOf("<link>") + 1);
            let startedIndex = splitedHeading.indexOf("<route>");
            let endedIndex = splitedHeading.indexOf("<route>", splitedHeading.indexOf("<route>") + 1);
            let routeWord = ""
            let beforeRouteWord = ""
            let afterRouteWord = ""
            let gradientWord = ""
            let beforeGradient = ""
            let afterGradient = ""


            for (let i = 0; i <= splitedHeading.length - 1; i++) {
                if (i >= startingIndex + 1 && i <= endingIndex - 1) {
                    gradientWord += splitedHeading[i] + " "

                }
                else if (i < startingIndex) {
                    beforeGradient += splitedHeading[i] + " "

                }
                else if (i > endingIndex) {
                    afterGradient += splitedHeading[i] + " "
                }
            }

            for (let i = 0; i <= splitedHeading.length - 1; i++) {
                if (i >= startedIndex + 1 && i <= endedIndex - 1) {
                    routeWord += splitedHeading[i] + " "

                }
                else if (i < startingIndex) {
                    beforeRouteWord += splitedHeading[i] + " "

                }
                else if (i > endingIndex) {
                    afterRouteWord += splitedHeading[i] + " "
                }


            }



            setGradientWords(gradientWord)
            setBeforeGradientWords(beforeGradient)
            setAfterGradientWords(afterGradient)
            setRouteWords(routeWord)
          
        }
        if (heading?.includes("<br>")) {

            let splitedHeading = heading.split(" ");
            let startingIndex = splitedHeading.indexOf("<br>");
            let endingIndex = splitedHeading.indexOf("<br>", splitedHeading.indexOf("<br>") + 1);
            let gradientWord = ""
            let beforeGradient = ""
            let afterGradient = ""


            for (let i = 0; i <= splitedHeading.length - 1; i++) {
                if (i >= startingIndex + 1 && i <= endingIndex - 1) {
                    gradientWord += splitedHeading[i] + " "

                }
                else if (i < startingIndex) {
                    beforeGradient += splitedHeading[i] + " "

                }
                else if (i > endingIndex) {
                    afterGradient += splitedHeading[i] + " "
                }
            }

            setGradientWords(gradientWord)
            setBeforeGradientWords(beforeGradient)
            setAfterGradientWords(afterGradient)

        }
       
        

    }, [heading])



    return (

        <Tag
            className={ClassName}
            style={{
                color: color ? color : "#00000",
                cursor: onClick && "pointer",
                fontSize: fontSize && fontSize,
                textShadow: textShadow?textShadow:'none', // Example text shadow
            }}
            onClick={onClick ? onClick : () => { }}
        >
            {/* {textSegments} */}
            
             

            {/* {
                !gradientWords && index ?
                    <>
                        <span className="text-gradient-heading">
                            {index}
                        </span>
                        <span className={`${index && 'px-3'}`}>
                            {heading}
                        </span>
                    </>
                    :
                    !gradientWords && !index ?
                        <>
                                {heading}
                        </>

                        :

                        <>
                            {beforeGradientWords}
                            <span onClick={() => { heading?.includes("<link>") && navigate(routeWords) }} style={{ cursor: heading?.includes("<link>") ? 'pointer' : null }} className={heading?.includes("<grad>") ? "text-gradient-heading" : redUnderline ?  "underline-red" : greenUnderline ? "underline-green": "underline-gradient"}>
                                {gradientWords}
                            </span>
                            {afterGradientWords}
                        </>
            } */}
            {
    !gradientWords && index ?
        <>
            <span className="text-gradient-heading">
                {index}
            </span>
            <span className={`${index && 'px-3'}`}>
                {heading}
            </span>
        </>
    :
    !gradientWords && !index ?
        <>
            {heading}
        </>
        :
        <>
            {beforeGradientWords}
            <span
                onClick={() => {
                    heading?.includes("<link>") && navigate(routeWords)
                }}
                style={{ cursor: heading?.includes("<link>") ? 'pointer' : null }}
                className={
                    heading?.includes("<grad>")
                        ? "text-gradient-heading"
                        : heading?.includes("<br>")
                        ? "text-break-heading"
                        : redUnderline
                        ? "underline-red"
                        : greenUnderline
                        ? "underline-green"
                        : "underline-gradient"
                }
            >
                {gradientWords}
            </span>
            {afterGradientWords}
        </>
}

        </Tag>


    );
});
export default Heading;