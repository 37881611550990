import React, { memo, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Heading from "../../elements/Heading";
import { useNavigate } from "react-router-dom";
import Button from "../../elements/Button";
import { useSelector } from 'react-redux';
import "./index.css"
const SimilarBlogs = memo((props) => {
    const region = useSelector((state) => state.region.region);
    const { content, textColor, backgroundColor, title, button, link, simple } = props;
    const navigate = useNavigate()
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <Container className="pb-lg-5 py-5" fluid style={{ backgroundColor: backgroundColor }}>

            <Container className="">
                <Row className="main-row">

                    <Col md={12} className="px-0">
                        <Heading fontFamily={"monument-extended"} size={"xxl"} fontSize="36px" heading={title} color={textColor} />
                    </Col>
                


                    {
                        content?.map((items, index) => {
                            return (

                                <Col md={4} className="py-3 px-md-3 px-lg-3 px-3 justify-centent-space-between">

                                    <Row className="blogs-images">

                                        <Col className="px-0 portfolio-image">
                                            <img src={items.image} width="100%" height="100%" alt="portfolio" />
                                        </Col>

                                    </Row>

                                    <Row className="">
                                        <Col className="py-0 px-0 pt-4 ">
                                            <Heading fontFamily={"monument-extended"} size="xxs" heading={items.title} color={textColor} />
                                        </Col>
                                    </Row>
                                    <Row className="px-0">

                                        <Col md={11} className="px-0 pb-4">
                                            <span
                                                onClick={() => navigate( region == "ae" ? `${items.link}`: `${items.link}` , { state: { id: items.id, name: items.name } })}
                                                style={{ cursor: 'pointer' }}>
                                                {/* <Heading bold className="hover-gradient-blogs" fontFamily={"monument-extended"} size="sm" heading={items.subtitle} color={textColor} /> */}
                                                <h4 className="hover-gradient-blogs" style={{ color: 'white', fontFamily: 'Monument', fontWeight: 'bold' }}>{items.subtitle}</h4>
                                            </span>
                                        </Col>

                                    </Row>

                                </Col>

                            )


                        })
                    }

                    <Col md={12} className="pt-5 px-0 pb-lg-0 pb-4" >
                        <Button onClick={() => navigate(link)}
                            title={button.title}
                            backgroundColor={button.backgroundColor}
                            textColor={button.textColor}
                            borderColor={simple ? button.borderColor : "gradient"}
                            gradientHover={button.gradientHover && true}
                        />
                    </Col>

                </Row>
            </Container>
        </Container>
    );
});

export default SimilarBlogs;
