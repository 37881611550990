import './index.css';
import React, { memo } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Heading from "../../elements/Heading";
import TopHeader from "../TopHeader";

const HomeHeader = memo((props) => {
  const { title, subtitle, textColor, image, logo, toggle, head1, head2, sizeHome } = props;

  return (
    <Container fluid className='p-0' style={{ backgroundColor: 'black' }}>
      <Row className="mb-5 m-0 width-100 py-2"
        style={{ position: "fixed", zIndex: '1000', width: '100%', backgroundColor: 'black' }}
      >
        <Col className='px-lg-4 px-md-4 py-2 mx-lg-5 px-md-4 mx-md-4'>
          <TopHeader image={logo} toggle={toggle} alt="image-here" loading="lazy" />
        </Col>
      </Row>
      <Row className=" m-0 pt-lg-2">
        <Col md={7} className="px-lg-5 pt-lg-0 pt-5 mt-lg-0 mt-5 d-flex align-items-start flex-column justify-content-center homeHeaderContent">
          <Heading bold fontFamily={"bold-font"} fontSize={"48px"} nomargin size={"xl"} heading={title} color={textColor} />
          <Heading fontFamily={"bold-font"} fontSize={"48px"} size="xl" heading={subtitle} color={textColor} />
        </Col>
        <Col md={5} className="px-0 pt-3 homeImage">
          <img src={image} width="100%" height="100%" alt="image-here" loading='lazy' />
        </Col>
      </Row>
    </Container>
  );
});

export default HomeHeader;
