import React, { useEffect, useState, useRef } from 'react';
import './App.css';
import Router from './router';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { AddingRegion } from './redux/RegionSlice';
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { Country } from "./content/Country"
function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const region = useSelector((state) => state.region.region);
  // const [userCountry, setUserCountry] = useState('');
  const [continent, setContinent] = useState('');

  const [userCity, setUserCity] = useState("");
  const [usersCountry, setUsersCountry] = useState("");
  const [userTimeZone, setUserTimeZone] = useState("");
  const isInitialRender = useRef(true);

  const mapCountryToContinent = (countryCode) => {
    // Define your countryToContinent mapping here
    const countryToContinent = {
      // North America
      "United States": "North America",
      "Canada": "North America",
      "Mexico": "North America",
      "Belize": "North America",
      "Guatemala": "North America",
      "El Salvador": "North America",
      "Honduras": "North America",
      "Costa Rica": "North America",
      "Nicaragua": "North America",
      "Panama": "North America",
      "Bahamas": "North America",
      "Cuba": "North America",
      "Jamaica": "North America",
      "Haiti": "North America",
      "Dominican Republic": "North America",
      "Puerto Rico": "North America",
      "Dominica": "North America",
      "Saint Kitts and Nevis": "North America",
      "Antigua and Barbuda": "North America",
      "Saint Vincent and the Grenadines": "North America",
      "Saint Lucia": "North America",
      "Barbados": "North America",
      "Grenada": "North America",
      "Trinidad and Tobago": "North America",
      // Add more countries and continents as needed
    };
    return countryToContinent[countryCode] || "ae";
  };
  // useEffect(() => {
  //   if (Intl) {
  //     const resolvedTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  //     setUserTimeZone(resolvedTimeZone);
  //     const tzArr = resolvedTimeZone.split("/");
  //     const city = tzArr[tzArr.length - 1];
  //     setUserCity(city);
  //     const country = Country[city];
  //     setUsersCountry(country);
  //     // setUserRegion(tzArr[0]);
  //   }
  // }, []);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     if (usersCountry) {
  //       const userContinent = mapCountryToContinent(usersCountry);
  //       
  //       setContinent(userContinent);
  //     } else {
  //       
  //     }
  //   };

  //   fetchData();
  // }, [usersCountry]);

  // dispatch(AddingRegion(continent));

  useEffect(() => {
    (function (h, o, t, j, a, r) {
      h.hj =
        h.hj ||
        function () {
          (h.hj.q = h.hj.q || []).push(arguments);
        };
      h._hjSettings = { hjid: 3487818, hjsv: 6 };
      a = o.getElementsByTagName('head')[0];
      r = o.createElement('script');
      r.async = 1;
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
      a.appendChild(r);
    })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
  }, []);

  // const [count,setCount] = useState(false)

  const url = new URL(window.location.href);
  const pathname = url.pathname;

  const segments = pathname.split('/');
  const desiredPath = '/' + segments.slice(2).join('/');
  // let desiredPath = pathname;

  // if (pathname.includes("/ae")) {
  //   const segments = pathname.split('/');
  //   desiredPath = '/' + segments.slice(2).join('/');
  // }
  // 

  useEffect(() => {
    if (region === "ae") {
      //  navigate('/ae-en')
      navigate(`/ae-en${desiredPath}`)
    }
    else {
      navigate(`/us-en${desiredPath}`)

    }


  }, [region]);

  // useEffect(()=>{
  //   genertedDyanmic()
  // },[])



  // useEffect(() => {
  //   if (region === "ae" && !pathname.startsWith("/ae-en")) {
  //     navigate(`/ae-en${desiredPath}`);
  //   }
  // }, [region, pathname, desiredPath]);
  return (
    <>
      <Helmet>
        <meta name="p:domain_verify" content="c0120825c72c6e616e118b0c8742add1" />
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-KGV1VTX46Q"></script>
        <script>
          {`
            {
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-KGV1VTX46Q');
            }
          `}
        </script>
        <script type="text/javascript" id="hs-script-loader" async defer src="//js-na1.hs-scripts.com/45313791.js"></script>
        <script>
          {`
            window[(function(_zDX,_Cj){var _nN='';for(var _wu=0;_wu<_zDX.length;_wu++){_nN==_nN;var _0p=_zDX[_wu].charCodeAt();_0p-=_Cj;_0p+=61;_Cj>4;_0p%=94;_0p!=_wu;_0p+=33;_nN+=String.fromCharCode(_0p)}return _nN})(atob('cWBnKygjfHotYnwy'), 23)] = '37c7e90e4e1680597800';     var zi = document.createElement('script');     (zi.type = 'text/javascript'),     (zi.async = true),     (zi.src = (function(_kAg,_gt){var _05='';for(var _Ga=0;_Ga<_kAg.length;_Ga++){var _NV=_kAg[_Ga].charCodeAt();_05==_05;_NV-=_gt;_NV!=_Ga;_gt>7;_NV+=61;_NV%=94;_NV+=33;_05+=String.fromCharCode(_NV)}return _05})(atob('KjY2MjVaT08sNU48K001JTQrMjY1TiUxL088K002IylOLDU='), 32)),     document.readyState === 'complete'?document.body.appendChild(zi):     window.addEventListener('load', function(){         document.body.appendChild(zi)     });
          `}
        </script>

      

      </Helmet>
      <div>
        <Router />
      </div>
    </>
  );
}

export default App;
