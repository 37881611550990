import React, { memo, useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Heading from "../../elements/Heading";
import EmailLogo from "../../../assets/images/EmailLogo.webp";
import PhoneLogo from "../../../assets/images/PhoneLogo.svg";
import AddressLogo from "../../../assets/images/AddressLogo.svg";
import { useNavigate } from "react-router-dom";
import "./index.css"
import Button from "../../elements/Button";
import { colors } from "../../../utils/colors";
import FacebookLogo from "../../../assets/images/FacebookLogoBlack.svg";
import InstagramLogo from "../../../assets/images/InstagramLogoBlack.svg";
import LinkedinLogo from "../../../assets/images/LinkedInLogoBlack.svg";
import YoutubeLogo from "../../../assets/images/YoutubeLogoBlack.svg";
import TwitterLogo from "../../../assets/images/TwitterLogoBlack.svg";
import PintrestLogo from "../../../assets/images/PintrestLogoBlack.svg";
import FacebookGradient from "../../../assets/images/facebook-gradient.webp";
import InstagramGradient from "../../../assets/images/instagram-gradient.svg";
import LinkdinGradient from "../../../assets/images/linkdin-gradient.svg";
import TwitterGradient from "../../../assets/images/twitter-gradient.svg";
import YoutubeGradient from "../../../assets/images/youtube-gradient.svg";
import PrintrestGradient from "../../../assets/images/printerest-gradient.svg";
import SocialMediaButton from "../SocialMediaButton";
import axios from "axios";
import Swal from "sweetalert2";
import { useSelector } from 'react-redux';
const BrandingFooter = memo((props) => {
  const region = useSelector((state) => state.region.region);
  const getGoogleMapsLink = (address) => {
    const encodedAddress = encodeURIComponent(address);
    return `https://www.google.com/maps?q=${encodedAddress}`;
  };
  const navigate = useNavigate();
  const { textColor, content, PhoneNumber, Email, Address, AddressAe, PhoneNumberAE,PhoneNumberUS,  title, subTitle, CopyRight, button, heading, homeFooter, space, SocialTitle } = props
  const [facebookLogoState, setFacebookLogoState] = useState();
  const [your_email, setEmail] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [instagramLogoState, setInstagramLogoState] = useState();
  const [linkdinLogoState, setLinkdinLogoState] = useState();
  const [twitterLogoState, setTwitterLogoState] = useState();
  const [youtubeLogoState, setYoutubeLogoState] = useState();
  const [pintrestLogoState, setPintrestLogoState] = useState();
  useEffect(() => {
    setFacebookLogoState(FacebookLogo);
    setInstagramLogoState(InstagramLogo);
    setLinkdinLogoState(LinkedinLogo);
    setTwitterLogoState(TwitterLogo);
    setYoutubeLogoState(YoutubeLogo);
    setPintrestLogoState(PintrestLogo);
  }, [
    FacebookLogo,
    InstagramLogo,
    LinkedinLogo,
    TwitterLogo,
    YoutubeLogo,
    PintrestLogo,
  ]);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const validateEmail = (email) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    return emailRegex.test(email);
  };

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    
    const valid = validateEmail(your_email);
    
    setIsValidEmail(valid);

    if (valid) {
        let formData = new FormData();    //formdata object
        formData.append('your_email', your_email);
       
        const config = {
          headers: { 'Content-Type': 'multipart/form-data' },
  
        }
  
        await axios.post("https://blog.icreativestudio.com/wp-json/contact-form-7/v1/contact-forms/21940/feedback",
          formData, config
        ).then((response) => {
          
          setEmail("");
           Swal.fire(
            "Successfully sent",
            "That thing is still around?",
            "success"
          );
        })
          .catch((error) => {
            // alert("saaad2");
            Swal.fire(
              "Incomplete Information",
              error,
              "error"
            );
          })
     
    }
    else {
      // alert("saaad3");
      Swal.fire(
        "Incomplete Information",
        "Please Enter A valid Email ",
        "error"
      )
    }
  };

  const changeHoverState = (value) => {
    if (value === "facebook gradient") {
      setFacebookLogoState(FacebookGradient);
    } else if (value === "facebook simple") {
      setFacebookLogoState(FacebookLogo);
    } else if (value === "instagram gradient") {
      setInstagramLogoState(InstagramGradient);
    } else if (value === "instagram simple") {
      setInstagramLogoState(InstagramLogo);
    } else if (value === "linkdin simple") {
      setLinkdinLogoState(LinkedinLogo);
    } else if (value === "linkdin gradient") {
      setLinkdinLogoState(LinkdinGradient);
    } else if (value === "twitter simple") {
      setTwitterLogoState(TwitterLogo);
    } else if (value === "twitter gradient") {
      setTwitterLogoState(TwitterGradient);
    } else if (value === "youtube simple") {
      setYoutubeLogoState(YoutubeLogo);
    } else if (value === "youtube gradient") {
      setYoutubeLogoState(YoutubeGradient);
    } else if (value === "pintrest simple") {
      setPintrestLogoState(PintrestLogo);
    } else if (value === "pintrest gradient") {
      setPintrestLogoState(PrintrestGradient);
    }
  };
  const socialMediaData = [
    {
      link: "//www.facebook.com/icreativesolofficial",
      icon: facebookLogoState,
      hoverState: "facebook gradient",
    },
    {
      link: "//www.instagram.com/ics.studiomedia/",
      icon: instagramLogoState,
      hoverState: "instagram gradient",
    },
    {
      link: "//www.linkedin.com/company/icreativesol/",
      icon: linkdinLogoState,
      hoverState: "linkdin gradient",
    },
    {
      link: "//youtube.com/@icreativesol5570",
      icon: youtubeLogoState,
      hoverState: "youtube gradient",
    },
    {
      link: "//twitter.com/ics_studiomedia",
      icon: twitterLogoState,
      hoverState: "twitter gradient",
    },
    {
      link: "//www.pinterest.com/icreativesolofficial",
      icon: pintrestLogoState,
      hoverState: "pintrest gradient",
    },
  ];
  
  

  return (
    <>
      <Container className="px-lg-0 " fluid style={{ backgroundColor: "white" }}>
        <Container className="pt-lg-5 pt-2 pb-3">
          <Row>
            <Col md={12}>
              <Heading bolder fontFamily={"bold-font"} fontSize={"20px"} size={"md"} heading={heading} color={textColor} />
            </Col>

            <Col lg={3} md={2}>
              {
                content.map((item, index) => {
                  return (
                    <Row className="mt-4 py-1">
                      <Link to={item.link} style={{ textDecoration: 'none' }}>
                        <Heading bolder fontFamily={"reg-font"} fontSize={"20px"} size={"md"} heading={item.content} color={textColor} />
                      </Link>
                    </Row>
                  )
                })
              }
            </Col>

            <Col lg={5} md={5} className="mt-3">
              <Row className="">
                <Col
                  md={8}
                  className="d-flex align-items-center px-lg-0 px-3 py-lg-3 py-2"
                >
                  <img width="20px" height="25px" src={PhoneLogo} alt="image-here" />
                  <div className="px-3">
                    <a
                      href={"tel:" + region == "ae" ? PhoneNumberAE: PhoneNumberUS}
                      className="text-black text-decoration-none"
                      style={{
                        fontFamily: "Montserrat-Regular",
                        fontSize: "18px"

                      }}
                    >
                      {region == "ae" ? PhoneNumberAE: PhoneNumberUS}
                    </a>
                  </div>
                </Col>

                <Col
                  md={8}
                  className="d-flex align-items-center px-lg-0 px-3 py-lg-3 py-2"
                >
                  <img width="20px"  height="25px" src={EmailLogo} alt="image-here" />
                  <div className="px-3">

                    <a
                      href={"mailto:" + Email}
                      className="text-black text-decoration-none"
                      style={{
                        fontFamily: "Montserrat-Regular",
                        fontSize: "18px",
                      }}
                    >
                      {Email}
                    </a>
                  </div>
                </Col>
                <Col md={12} className="d-flex align-items-center px-lg-0 px-3 py-lg-3 py-2">
                  <img width="20px" height="25px" src={AddressLogo} alt="image-here" />
                  <Row className="d-flex flex-column ms-1">
                   
                    <div className="px-3 py-1">
                      <a
                        href={getGoogleMapsLink(region == "ae" ? AddressAe: Address)}
                        target="_blank" // Open link in a new tab
                        rel="noopener noreferrer" // Recommended for security
                        className="text-black text-decoration-none"
                        style={{
                          fontFamily: "Montserrat-Regular",
                          fontSize: "18px",
                        }}
                      >
                        {region == "ae" ? AddressAe: Address}
                      </a>
                    </div>
                  </Row>
                </Col>
              </Row>
            </Col>

            <Col lg={4} md={5} className="mt-4">
              <div className="">
                <Heading size={'lg'} fontFamily={"bold-font"} fontSize={"22px"} heading={title} color='black' />
                <Heading size={'xxs'} fontFamily={"reg-font"} fontSize={"14px"} heading={subTitle} color={textColor} />
              </div>
              <form className='newsletter' onSubmit={handleEmailSubmit}>
                <img src={EmailLogo}  alt="Email Icon" className="newsletterImg img-fluid" />
                <input type="email" placeholder="Enter your email" className="newsletterInput"
                 onChange={handleEmailChange}
                  value={your_email}
                  
                  />
                <div className="ms-5 ms-lg-5">
                  <Button
                    title={button.title}
                    textColor={button.textColor}
                    width="120px"
                    borderRadius={button.borderRadius}
                    backgroundImage={colors.gradient}
                    fontSize="12px"
                    fontWeight="600"
                    // onClick={handleEmail}
                    type="submit"
                  />
                </div>
              </form>
              <Col className="mt-3 py-2">
                <Heading size={'lg'} fontFamily={"bold-font"} fontSize={"22px"} heading={SocialTitle} color='black' />
              </Col>

              <Col
                // lg={12}
                md={12}
                className=" d-flex justify-content-between mt-3"
              >
                {
                  socialMediaData.map((data, index) => (
                    <SocialMediaButton
                      key={index}
                      link={data.link}
                      icon={data.icon}
                      hoverState={data.hoverState}
                      onMouseOver={changeHoverState}
                      onMouseLeave={changeHoverState}
                    />
                  ))
                }
              </Col>
            </Col>

            <Col md={12} className="d-flex justify-content-center py-lg-4 mt-4 pb-4">
              <Heading size={'xxs'} fontFamily={"reg-font"} fontSize={"14px"} heading={CopyRight} color={textColor} />
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  )
})

export default BrandingFooter;
